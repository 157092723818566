import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { LogoLayout, Button, LinkButton } from "..";
import { SyncIcon } from "../Icons";

import styles from "./ErrorBoundary.module.scss";

interface Props {
  className?: string;
}

const ErrorBoundary = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <LogoLayout>
      <div className={classnames(styles.errorBoundary, className)}>
        <h1>{t("errorBoundary.title")}</h1>
        <Button
          id="reload-app-btn"
          variant="filled"
          iconComponent={SyncIcon}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("errorBoundary.reload")}
        </Button>
        <LinkButton id="return-home-btn" isExternal to="/" variant="inline">
          {t("errorBoundary.return")}
        </LinkButton>
      </div>
    </LogoLayout>
  );
};

export default ErrorBoundary;
