import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { ArrowLeft, ArrowRight } from "fe-shared/src/components/Icons";
import { Button, NavigationLink, HelpPortal } from "fe-shared/src/components";
import useMediaQuery from "fe-shared/src/hooks/useMediaQuery";
import { get, set } from "fe-shared/src/helpers/storage";
import { RoutesMapConfig } from "helpers/routesMapConfig";
import UserContext from "contexts/userContext";

import { BusinessRole } from "fe-shared/src/types/domain";

import config from "config";

import styles from "./Navigation.module.scss";

export interface Props {
  routes: RoutesMapConfig;
  businessRoleCode?: BusinessRole;
}

const HELP_LINK_MAP: { [key in BusinessRole]?: string } = {
  [BusinessRole.Producer]: config.HELP_PORTAL_URL_PRODUCER,
  [BusinessRole.Hauler]: config.HELP_PORTAL_URL_HAULER,
  [BusinessRole.Collector]: config.HELP_PORTAL_URL_COLLECTOR,
  [BusinessRole.Processor]: config.HELP_PORTAL_URL_PROCESSOR,
};

const Navigation = (props: Props) => {
  const { routes, businessRoleCode } = props;
  const userContext = useContext(UserContext);
  const token = userContext.user?.token;
  const [isCollapsed, setCollapsed] = useState(() =>
    get("navigationCollapsed")
  );
  const { t } = useTranslation();
  const isMediaQuery = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    const collapsedState = isMediaQuery ? get("navigationCollapsed") : false;

    setCollapsed(collapsedState);
  }, [isMediaQuery, setCollapsed]);

  const changeNavigationState = () => {
    set("navigationCollapsed", !isCollapsed);
    setCollapsed(!isCollapsed);
  };

  return (
    <nav
      className={classnames({
        [styles.navigation]: true,
        [styles.navigationCollapsed]: isCollapsed,
      })}
    >
      <div
        className={classnames({
          [styles.navigationWrapper]: true,
          [styles.navigationWrapperCollapsed]: isCollapsed,
        })}
      >
        <Button
          id="collapse-menu"
          aria-label={t(`navigation.${isCollapsed ? "expand" : "collapse"}`)}
          className={classnames({
            [styles.navigationCollapseButton]: true,
            [styles.navigationButtonCollapsed]: isCollapsed,
          })}
          variant="outlined"
          iconComponent={isCollapsed ? ArrowRight : ArrowLeft}
          iconPosition="left"
          onClick={changeNavigationState}
        >
          {!isCollapsed && "Collapse"}
        </Button>
        <ul>
          {routes.map(({ icon: Icon, label, path }) => {
            return (
              <li key={label}>
                <NavigationLink
                  className={classnames({
                    [styles.navigationLink]: true,
                    [styles.navigationLinkCollapsed]: isCollapsed,
                  })}
                  activeClassName={styles.navigationLinkActive}
                  iconClassName={classnames({
                    [styles.navigationLinkIconCollapsed]: isCollapsed,
                  })}
                  link={path}
                  text={t(label)}
                  iconComponent={Icon}
                  isCollapsed={isCollapsed}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <HelpPortal
        isCollapsed={isCollapsed}
        link={`${HELP_LINK_MAP[businessRoleCode!]}?token=${token}`}
      />
    </nav>
  );
};

export default Navigation;
