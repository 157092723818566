import axios from "axios";

import { RecordsListItem } from "../../../types/common";

export enum RecordType {
  historyRecords = "history records",
  notes = "notes",
}

export interface GetActivityParams {
  entityType: string;
  objectId?: number | string | null;
  searchParams?: string;
}

export interface AddActivityParams extends GetActivityParams {
  initiatorEmail: string;
  message: string;
  recordType: RecordType;
}

export interface ActivityRecordResponse extends AddActivityParams {
  id: number;
  createdTime: string;
  initiator: string;
}

export const getActivities = ({
  entityType,
  objectId,
  searchParams,
}: GetActivityParams): Promise<RecordsListItem[]> =>
  axios
    .get<ActivityRecordResponse[]>(
      `${entityType}/${objectId ? `${objectId}/` : ""}history${
        searchParams ? `?${searchParams}` : ""
      }`
    )
    .then((res) =>
      (res.data || []).reverse().map((i, idx) => ({
        id: i.id || idx,
        createdDate: i.createdTime,
        createdBy: i.initiator,
        text: i.message,
      }))
    );

export const addActivity = ({
  entityType,
  objectId,
  ...params
}: AddActivityParams): Promise<any> =>
  axios
    .post(
      `${entityType}/${objectId ? `${objectId}/` : ""}history/export`,
      params
    )
    .then((res) => res.data);
