import parseJSON from "date-fns/parseJSON";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

export const getNoOffsetDate = (date: Date | null = null) =>
  date && new Date(date.getTime() - date.getTimezoneOffset() * 60000);

export const getOffsetDate = (date: Date | null = null) =>
  date && new Date(date.getTime() + date.getTimezoneOffset() * 60000);

export const getDateObject = (dateString: string | null) =>
  dateString ? parseJSON(dateString) : null;

export const getDateString = (dateObject: Date | null) =>
  dateObject ? dateObject.toISOString() : null;

export const getStartOfMonth = (dateObject: Date | null) =>
  dateObject ? getNoOffsetDate(startOfMonth(dateObject)) : null;

export const getEndOfMonth = (dateObject: Date | null) =>
  dateObject ? getNoOffsetDate(endOfMonth(dateObject)) : null;
