import React, { ButtonHTMLAttributes, forwardRef } from "react";
import classnames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";

import styles from "./IconButton.module.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  "aria-label": string;
  hasTooltip?: boolean;
}

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { hasTooltip, children, className, ...otherProps } = props;

  const button = (
    <button
      ref={ref}
      type="button"
      className={classnames(styles.iconButton, className)}
      {...otherProps}
    >
      {children}
    </button>
  );

  return hasTooltip ? (
    <Tippy
      placement="top"
      popperOptions={popperOptions}
      content={otherProps["aria-label"]}
    >
      {button}
    </Tippy>
  ) : (
    button
  );
});

export default IconButton;
