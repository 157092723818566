import React from "react";

import { IconProps } from "../../types/common";

const SyncIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#7DCB89",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M12 2.20954V3.99954C16.42 3.99954 20 7.57954 20 11.9995C20 13.0395 19.8 14.0395 19.43 14.9495C19.16 15.6195 18.3 15.7995 17.79 15.2895C17.52 15.0195 17.41 14.6095 17.56 14.2495C17.85 13.5595 18 12.7895 18 11.9995C18 8.68954 15.31 5.99954 12 5.99954V7.78954C12 8.23954 11.46 8.45954 11.14 8.14954L8.35 5.35954C8.15 5.15954 8.15 4.84954 8.35 4.64954L11.15 1.85954C11.46 1.53954 12 1.75954 12 2.20954ZM6 11.9995C6 15.3095 8.69 17.9995 12 17.9995V16.2095C12 15.7595 12.54 15.5395 12.85 15.8495L15.64 18.6395C15.84 18.8395 15.84 19.1495 15.64 19.3495L12.85 22.1395C12.54 22.4595 12 22.2395 12 21.7895V19.9995C7.58 19.9995 4 16.4195 4 11.9995C4 10.9595 4.2 9.95954 4.57 9.04954C4.84 8.37954 5.7 8.19954 6.21 8.70954C6.48 8.97954 6.59 9.38954 6.44 9.74954C6.15 10.4395 6 11.2095 6 11.9995Z"
    />
  </svg>
);

export default SyncIcon;
