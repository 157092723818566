import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import UserProfile from "components/UserProfile";

import { ROUTES } from "consts";
import LOGO_URL from "fe-shared/src/assets/images/logo.svg";
import { Company } from "fe-shared/src/types/domain";
import CompaniesSwitcher from "../CompaniesSwitcher";

import styles from "./Header.module.scss";

export interface Props {
  currentCompany: Company;
  companies: Company[];
  onChooseCompany: (company: Company) => void;
}

const Header = (props: Props) => {
  const { t } = useTranslation();

  return (
    <header className={styles.etHeader}>
      <div className={styles.etHeaderLeftContainer}>
        <div className={styles.etHeaderLogoContainer}>
          <Link to={ROUTES.root} className={styles.etHeaderLogo}>
            <img
              src={LOGO_URL}
              alt={t("logoAltText")}
              className={styles.etHeaderLogoImage}
            />
          </Link>
        </div>
        <CompaniesSwitcher {...props} />
      </div>
      <UserProfile />
    </header>
  );
};

export default Header;
