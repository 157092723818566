/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ObjectSchema, ValidationErrorItem } from "@hapi/joi";
import memoize from "memoize-one";
import { useTranslation } from "react-i18next";
import { useState, useCallback, useMemo } from "react";
import get from "lodash/get";
import clone from "lodash/clone";
import setWith from "lodash/setWith";

import { getDirtyKeys, getDefaultErrorMessages } from "../helpers/validation";

import { ErrorMessages, Errors, DirtyKeys } from "../types/common";

const DEFAULT_ERROR_DETAILS: ValidationErrorItem[] = [];

const getUpdatedState = <T extends object>(
  prevState: T,
  key: string,
  value: any
): T => setWith<T>(clone(prevState), key, value, clone);

const useValidation = <T>(
  validationConfig: ObjectSchema<T>,
  initialContent: T,
  errorMessages: ErrorMessages
) => {
  type FormData = {
    values: T;
    dirtyKeys: DirtyKeys;
  };

  const { t } = useTranslation();

  // eslint-disable-next-line
  const getValidationResult = useCallback(
    memoize((values: T) =>
      validationConfig.validate(values, {
        abortEarly: false,
        messages: getDefaultErrorMessages(t),
      })
    ),
    [validationConfig, t]
  );

  const [isValid, setIsValid] = useState(
    () => !getValidationResult(initialContent).error
  );
  const [validatedValues, setValidatedValues] = useState<T>(
    () => getValidationResult(initialContent).value
  );
  const [formData, setFormData] = useState<FormData>(() => ({
    values: initialContent,
    dirtyKeys: getDirtyKeys(initialContent),
  }));
  const [errorDetails, setErrorDetails] = useState(DEFAULT_ERROR_DETAILS);

  const errors = useMemo(() => {
    const getErrorMessage = (detail: ValidationErrorItem) => {
      const lastKey = detail.path[detail.path.length - 1];
      const stringOnlyKey = detail.path
        .filter((s) => typeof s === "string")
        .join(".");

      const keyErrorMessages =
        errorMessages[stringOnlyKey] || errorMessages[lastKey];
      // treat null strings as empty
      const type = detail.type === "string.base" ? "string.empty" : detail.type;

      return t(keyErrorMessages?.[type]) || detail?.message || "";
    };

    return errorDetails.reduce<Errors>((result, detail) => {
      const valueKey = detail.context?.label as string;

      if (valueKey && formData.dirtyKeys[valueKey]) {
        result[valueKey] = getErrorMessage(detail);
      }

      return result;
    }, {});
  }, [formData.dirtyKeys, errorDetails, errorMessages, t]);

  const validate = (newFormData = formData, shouldScroll = false) => {
    const { error, value } = getValidationResult(newFormData.values);
    const isFormValid = !error;

    setErrorDetails(error?.details || DEFAULT_ERROR_DETAILS);
    setIsValid(isFormValid);
    setValidatedValues(value);

    if (!isFormValid && shouldScroll) {
      const key = error?.details[0].context?.label;
      const firstErrorNode = key ? document.getElementById(key) : null;

      firstErrorNode?.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    return isFormValid;
  };

  const validateAll = () => {
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        dirtyKeys: Object.keys(prevFormData.dirtyKeys).reduce((result, key) => {
          result[key] = true;
          return result;
        }, {} as DirtyKeys),
      };

      validate(newFormData, true);

      return newFormData;
    });
  };

  const updateValue = (
    key: string,
    updatedValue: unknown,
    isValidated = false,
    isUpdateDirtyKeys = false
  ) => {
    setFormData((prevFormData) => {
      let newFormData = getUpdatedState(
        prevFormData,
        `values.${key}`,
        updatedValue
      );

      if (prevFormData.dirtyKeys[key] !== isValidated) {
        newFormData = getUpdatedState(
          newFormData,
          `dirtyKeys["${key}"]`,
          isValidated
        );
      }

      if (isUpdateDirtyKeys) {
        const newDirtyKeys = {
          ...getDirtyKeys(newFormData.values),
          ...newFormData.dirtyKeys,
        };

        newFormData = {
          ...newFormData,
          dirtyKeys: newDirtyKeys,
        };
      }

      if (isValidated) {
        validate(newFormData);
      }

      return newFormData;
    });
  };

  const validateValue = (key: string) => {
    setFormData((prevFormData) => {
      let newFormData = prevFormData;

      if (!prevFormData.dirtyKeys[key]) {
        newFormData = getUpdatedState(newFormData, `dirtyKeys["${key}"]`, true);
      }

      validate(newFormData);

      return newFormData;
    });
  };

  const addArrayValue = (key: string, value: Record<string, any>) => {
    setFormData((prevFormData) => {
      const currentArray = get(prevFormData, `values.${key}`, []);
      const updatedArray = [...currentArray, value];
      const updatedFormData = getUpdatedState(
        prevFormData,
        `values.${key}`,
        updatedArray
      );
      const newIndex = updatedArray.length - 1;
      const newDirtyKeys = getDirtyKeys(value, `${key}[${newIndex}].`);
      const newFormData = {
        ...updatedFormData,
        dirtyKeys: {
          ...updatedFormData.dirtyKeys,
          ...newDirtyKeys,
          [key]: true,
        },
      };

      validate(newFormData);

      return newFormData;
    });
  };

  const removeArrayValue = (key: string, index: number) => {
    setFormData((prevFormData) => {
      const currentArray = get(prevFormData, `values.${key}`, []) as [];
      const updatedArray = currentArray.filter((_, idx) => idx !== index);
      const updatedFormData = getUpdatedState(
        prevFormData,
        `values.${key}`,
        updatedArray
      );
      const dirtyKeyRegex = new RegExp(`(${key}\\[)(\\d+)(\\].*)`);
      const replacer = (
        match: string,
        keyStart: string,
        currentIndex: string,
        keyEnd: string
      ) => {
        const intIndex = Number(currentIndex);
        const newIndex = intIndex >= index ? intIndex - 1 : intIndex;

        return `${keyStart}${newIndex}${keyEnd}`;
      };

      // 1) Omit dirty keys with removed index
      // 2) Shift dirty keys with higher than removed index
      const newDirtyKeys = Object.keys(prevFormData.dirtyKeys).reduce(
        (result, dirtyKey) => {
          if (!dirtyKey.startsWith(`${key}[${index}]`)) {
            const newKey = dirtyKey.replace(dirtyKeyRegex, replacer);

            result[newKey] = prevFormData.dirtyKeys[dirtyKey];
          }
          return result;
        },
        {} as DirtyKeys
      );
      const newFormData = {
        ...updatedFormData,
        dirtyKeys: {
          ...newDirtyKeys,
          [key]: true,
        },
      };

      validate(newFormData);

      return newFormData;
    });
  };

  const reset = (initialValues: T) => {
    setFormData({
      values: initialValues,
      dirtyKeys: getDirtyKeys(initialValues),
    });
    setIsValid(!getValidationResult(initialValues).error);
    setValidatedValues(getValidationResult(initialContent).value);
    setErrorDetails(DEFAULT_ERROR_DETAILS);
  };

  return {
    errors,
    errorDetails,
    isValid,
    validate,
    validateAll,
    updateValue,
    validateValue,
    addArrayValue,
    removeArrayValue,
    validatedValues,
    reset,
    ...formData,
  };
};

export default useValidation;
