import axios from "axios";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showNotification } from "../components";
import { CloseIcon } from "../components/Icons";

declare module "axios" {
  export interface AxiosRequestConfig {
    hideToast?: boolean;
    errorMessage?: string;
  }
}

const useGlobalErrorHandler = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403
        ) {
          const errorMessage = t(`errors.${error.response?.data.errorCode}`, {
            defaultValue:
              error.response?.data.message ||
              t(error.config.errorMessage, { defaultValue: "" }) ||
              error.message,
          });

          if (!error.config.hideToast) {
            showNotification(errorMessage, undefined, CloseIcon);
          }

          Sentry.withScope((scope) => {
            scope.setFingerprint(
              [
                errorMessage,
                error?.config?.url,
                error?.response?.status?.toString(),
                error.response?.data?.errorCode?.toString(),
                error?.response?.data?.additionalInfo?.[0]?.code,
              ].filter(Boolean)
            );
            Sentry.captureException(error, {
              extra: {
                response: error?.response?.data,
                errorMessage,
                additionalInfo: error?.response?.data?.additionalInfo,
              },
            });
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [t]);
};

export default useGlobalErrorHandler;
