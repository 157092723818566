import { Joi, emailJoiSchema } from "fe-shared/src/helpers/validation";
import { EMAIL_ERROR_MESSAGES } from "fe-shared/src/helpers/errorMessages";

import { ErrorMessages, FormConfiguration } from "fe-shared/src/types/common";

import * as api from "./api";

export const errorMessages: ErrorMessages = {
  ...EMAIL_ERROR_MESSAGES,
};

const initialContent = {
  email: "",
};

const validationConfig = Joi.object({
  email: emailJoiSchema,
});

export const FORM_CONFIGURATION: FormConfiguration<api.ForgotPasswordParams> = {
  initialContent,
  validationConfig,
};
