import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { MutationResult } from "react-query";

import { ApiErrorBanner } from "fe-shared/src/components";

import { ApiError } from "fe-shared/src/types/common";

export interface ErrorAdditionalInfo {
  code: string;
  description: string;
  details: {
    email: string;
    companyId: number;
    legalName: string;
    operatingName: string;
  };
}

export interface PasswordApiError extends ApiError {
  additionalInfo?: ErrorAdditionalInfo[];
}

interface Props {
  requestStatus: MutationResult<unknown, AxiosError<PasswordApiError>>;
}

const PasswordErrorBanner = (props: Props) => {
  const { t } = useTranslation();
  const { requestStatus } = props;
  const errorCode =
    requestStatus.error?.response?.data.additionalInfo?.[0].code;

  return (
    <ApiErrorBanner id="password-error-banner" requestStatus={requestStatus}>
      {errorCode ? t(`errors.${errorCode}`) : null}
    </ApiErrorBanner>
  );
};

export default PasswordErrorBanner;
