import React from "react";

import { IconProps } from "../../types/common";

const OutlinedInfoIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#787F84",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M12 5C8.136 5 5 8.136 5 12C5 15.864 8.136 19 12 19C15.864 19 19 15.864 19 12C19 8.136 15.864 5 12 5ZM11.3 8.5V9.9H12.7V8.5H11.3ZM11.3 11.3V15.5H12.7V11.3H11.3ZM6.4 12C6.4 15.087 8.913 17.6 12 17.6C15.087 17.6 17.6 15.087 17.6 12C17.6 8.913 15.087 6.4 12 6.4C8.913 6.4 6.4 8.913 6.4 12Z"
    />
  </svg>
);

export default OutlinedInfoIcon;
