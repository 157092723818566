import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import {
  Button,
  TextInput,
  Loader,
  ApiErrorBanner,
  Checkbox,
  LinkButton,
  LogoLayout,
} from "fe-shared/src/components";

import useLogin from "hooks/useLogin";
import useValidation from "fe-shared/src/hooks/useValidation";

import { FORM_CONFIGURATION, errorMessages } from "./modules/config";

import styles from "./LoginView.module.scss";

const LoginView = () => {
  const [isKeepLoginChecked, setIsKeepLoginChecked] = useState<boolean>(false);
  const [login, mutationResult] = useLogin(isKeepLoginChecked, false);
  const { isLoading, isError } = mutationResult;

  const { t } = useTranslation();
  const { validationConfig, initialContent } = FORM_CONFIGURATION;

  const {
    values,
    errors,
    updateValue,
    validateValue,
    validateAll,
    isValid,
  } = useValidation(validationConfig, initialContent, errorMessages);

  const tryLogin = useCallback(() => {
    validateAll();
    if (isValid) {
      login(values);
    }
  }, [isValid, login, validateAll, values]);

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      <h1>{t("login.welcome")}</h1>
      {isError && (
        <ApiErrorBanner
          id="login-error-banner"
          requestStatus={mutationResult}
        />
      )}
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          tryLogin();
        }}
        aria-label={t("login.form")}
        className={styles.etLoginControls}
      >
        <TextInput
          name="email"
          type="email"
          id="email-input"
          autoComplete="on"
          title={t("email")}
          value={values.email}
          isError={!!errors.email}
          errorText={errors.email}
          onBlur={() => validateValue("email")}
          inputClassName={styles.etLoginInput}
          onChange={(e) => updateValue("email", e.target.value)}
        />
        <div className={styles.etLoginForgotWrapper}>
          <TextInput
            name="password"
            type="password"
            id="password-input"
            autoComplete="off"
            title={t("password")}
            value={values.password}
            isError={!!errors.password}
            errorText={errors.password}
            inputClassName={styles.etLoginInput}
            onBlur={() => validateValue("password")}
            onChange={(e) => updateValue("password", e.target.value)}
          />
          <LinkButton
            to="/forgot-password"
            id="forgot-pass-link"
            variant="inline"
            className={classnames([
              styles.etLoginForgotButton,
              styles.btnCustomized,
            ])}
          >
            {t("login.forgotPassword")}
          </LinkButton>
        </div>
        <div className={styles.etLoginBottomLine}>
          <Checkbox
            id="keep-logedin"
            title={t("login.keepMeLoggedin")}
            onChange={(e, v) => setIsKeepLoginChecked(v)}
            value={isKeepLoginChecked}
          />
          <Button
            type="submit"
            id="start-application-button"
            disabled={
              !values.email || !values.password || !!Object.keys(errors).length
            }
          >
            {t("login.button")}
          </Button>
        </div>
      </form>
    </LogoLayout>
  );
};

export default LoginView;
