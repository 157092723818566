import React from "react";
import classnames from "classnames";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import useMediaQuery from "../../hooks/useMediaQuery";
import { OutlinedInfoIcon } from "../Icons";
import styles from "./Tooltip.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
}

const Tooltip = (props: Props) => {
  const { children, className, wrapperClassName } = props;
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <Tippy
      interactive
      content={children}
      placement={isMobile ? "bottom" : "right-start"}
      appendTo={() => document.body}
      className={classnames(styles.tooltipBody, wrapperClassName)}
      arrow={false}
      popperOptions={{
        strategy: "fixed",
      }}
    >
      <div className={classnames(styles.tooltipBlock, className)}>
        <OutlinedInfoIcon />
      </div>
    </Tippy>
  );
};

export default Tooltip;
