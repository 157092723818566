import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Location } from "history";
import { get, set } from "../helpers/storage";
import isDisabledRoute from "../helpers/isDisabledRoute";

const useHistoryListener = (
  prevLocationKey: string,
  rootLocation: string,
  disabledRoutes: string[] = []
) => {
  const history = useHistory();
  const [historyStack, setHistoryStack] = useState<Location<any>[]>([]);
  const [isInitLocationSaved, setInitLocationSaved] = useState(false);

  const goBack = useCallback(() => {
    const savedPrevLocation = get(prevLocationKey, rootLocation, true);
    const filteredHistory = historyStack.filter(
      (item) => !isDisabledRoute(disabledRoutes, item.pathname)
    );
    const prevLocation = filteredHistory[0]?.pathname || savedPrevLocation;

    history.replace(prevLocation);
  }, [disabledRoutes, rootLocation, history, historyStack, prevLocationKey]);

  useEffect(() => {
    if (!isInitLocationSaved) {
      setHistoryStack([history.location]);
      setInitLocationSaved(true);

      if (!isDisabledRoute(disabledRoutes, history.location.pathname)) {
        set(prevLocationKey, history.location.pathname, true);
      }
    }
  }, [
    prevLocationKey,
    disabledRoutes,
    setHistoryStack,
    history,
    isInitLocationSaved,
  ]);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setHistoryStack((prevState) => {
        if (prevState[0].pathname === location.pathname) {
          return prevState;
        }

        const newState = [location, ...prevState.slice(0, 4)];

        if (!isDisabledRoute(disabledRoutes, location.pathname)) {
          set(prevLocationKey, location.pathname, true);
        }

        return newState;
      });
    });

    return () => unlisten();
  }, [disabledRoutes, prevLocationKey, setHistoryStack, history]);

  return {
    historyStack,
    goBack,
  };
};

export default useHistoryListener;
