import React from "react";

import { IconProps } from "../../types/common";

const FacilityIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M12 7h10v14H2V3h10v4zM4 19h2v-2H4v2zm2-4H4v-2h2v2zm-2-4h2V9H4v2zm2-4H4V5h2v2zm2 12h2v-2H8v2zm2-4H8v-2h2v2zm-2-4h2V9H8v2zm2-4H8V5h2v2zm2 12h8V9h-8v2h2v2h-2v2h2v2h-2v2zm6-8h-2v2h2v-2zm-2 4h2v2h-2v-2z"
    />
  </svg>
);

export default FacilityIcon;
