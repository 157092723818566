import { useLayoutEffect, RefObject } from "react";

const DEFAULT_ITEM_HEIGHT = 32;
const defaultOptions: unknown[] = [];

const useScrollToIndex = (
  isDropdownOpened: boolean,
  selectedIdx: number,
  optionsWrapperRef: RefObject<HTMLElement>,
  options: unknown[] = defaultOptions,
  itemHeight: number = DEFAULT_ITEM_HEIGHT
) => {
  useLayoutEffect(() => {
    const scrollToIdx = (currentValueIdx: number) => {
      if (optionsWrapperRef.current) {
        const { scrollHeight, clientHeight } = optionsWrapperRef.current;

        optionsWrapperRef.current.scrollTop = Math.round(
          (currentValueIdx / options.length) * scrollHeight -
            clientHeight / 2 +
            itemHeight / 2
        );
      }
    };

    if (isDropdownOpened && optionsWrapperRef.current) {
      scrollToIdx(selectedIdx);
    }
  }, [isDropdownOpened, selectedIdx, options, optionsWrapperRef, itemHeight]);
};

export default useScrollToIndex;
