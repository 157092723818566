import React from "react";

import { IconProps } from "../../types/common";

import ArrowRight from "./ArrowRight";

const ArrowDown = ({ style = {}, ...otherProps }: IconProps) => (
  <ArrowRight
    style={{
      ...style,
      transform: "rotate(90deg)",
    }}
    {...otherProps}
  />
);

export default ArrowDown;
