import axios from "axios";

export interface ForgotPasswordParams {
  email: string;
}

export const forgotPassword = ({
  email,
}: ForgotPasswordParams): Promise<unknown> =>
  axios
    .post(
      "account/generateActionToken",
      {
        email,
        type: "forgot-password",
      },
      { hideToast: true }
    )
    .then((res) => res.data);
