import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { ArrowRight } from "../Icons";
import styles from "./NavigationLink.module.scss";

interface Props {
  link: string;
  text: string;
  className?: string;
  activeClassName?: string;
  iconClassName?: string;
  iconComponent?: React.ElementType;
  isDisabled?: boolean;
  isArrowVisible?: boolean;
  isCollapsed?: boolean;
}

const NavigationLink = (props: Props) => {
  const {
    link,
    text,
    className,
    activeClassName,
    iconClassName,
    iconComponent: IconComponent,
    isDisabled = false,
    isArrowVisible = false,
    isCollapsed = false,
  } = props;

  const onMouseDown = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.currentTarget.focus();
    e.currentTarget.blur();
  };

  return (
    <NavLink
      onMouseDown={onMouseDown}
      tabIndex={isDisabled ? -1 : 0}
      to={link}
      className={classnames(
        styles.navigationLink,
        {
          [styles.navigationLinkDisabled]: isDisabled,
        },
        className
      )}
      activeClassName={classnames(styles.navigationLinkActive, activeClassName)}
    >
      {IconComponent && (
        <IconComponent
          fillColor={null}
          className={classnames(styles.navigationLinkIcon, iconClassName)}
        />
      )}
      {!isCollapsed && text}
      {isArrowVisible && <ArrowRight className={styles.navigationLinkArrow} />}
    </NavLink>
  );
};

export default NavigationLink;
