import { createContext } from "react";
import { ExternalUserInfo } from "types";

interface UserContextModel {
  user: ExternalUserInfo | null;
  sessionId: string | null;
  setData: (data: ExternalUserInfo | null) => void;
  setIsKeepLoginChecked: (isKeepLoginChecked: boolean) => void;
}

const UserContext = createContext<UserContextModel>({
  user: null,
  sessionId: null,
  setData: () => {},
  setIsKeepLoginChecked: () => {},
});

export default UserContext;
