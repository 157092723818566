import React from "react";

import { IconProps } from "../../types/common";

const ClearFiltersIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
    >
      <path d="M9.73 6H4a1 1 0 00-1 1 1 1 0 001 1h6.89L9.73 6zm3.47 2l-1.16-2H20a1 1 0 011 1 1 1 0 01-1 1h-6.8zm-.58 3H7a1 1 0 00-1 1 1 1 0 001 1h6.77l-1.15-2zm3.46 2l-1.15-2H17a1 1 0 011 1 1 1 0 01-1 1h-.92zM13 18h-2a1 1 0 01-1-1 1 1 0 011-1h2a1 1 0 011 1 1 1 0 01-1 1z" />
      <path d="M7.87 2.5a1 1 0 00-.37 1.37l8 13.85a1 1 0 001.37.37 1 1 0 00.36-1.37l-8-13.85a1 1 0 00-1.36-.37z" />
    </g>
  </svg>
);

export default ClearFiltersIcon;
