import BusinessRole from "./BusinessRole";

export enum MaterialUnit {
  Kg = "Kg",
  Units = "Units",
}

export enum MaterialCategoryName {
  Tires = "Tires",
  Products = "Products Produced",
  NonEligibleMaterials = "Non-Eligible Materials",
}

export interface MaterialConfig {
  code: string;
  name: string;
}

export interface TireMaterialConfig extends MaterialConfig {
  hintText?: string;
  allowedRoles?: BusinessRole[];
  standardWeight: number;
}

export interface NonEligibleMaterialConfig extends MaterialConfig {
  isScaleTicketRequired: boolean;
}

export interface MaterialGroup<T> {
  items: T[];
  unit: MaterialUnit;
  materialCategoryName: MaterialCategoryName;
  regYear?: number | null;
  total?: number;
  weight?: number;
}

export interface MaterialGroupByRegYear<T> {
  regYear?: number | null;
  weight: number;
  materialGroups: MaterialGroup<T>[];
}

export interface MaterialInfo {
  units: {
    unit: MaterialUnit;
    unitQuantity: number;
    weight?: number;
  };
  materialCategoryName: MaterialCategoryName;
  materialCode: string;
  materialName: string;
}
