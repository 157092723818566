import {
  UsersIcon,
  FacilityIcon,
  TransactionsIcon,
  RequestListIcon,
  TransfersIcon,
  InventoryIcon,
  FolderOpenIcon,
  EndMarketIcon,
  InvoiceIcon,
  PaymentBalanceIcon,
} from "fe-shared/src/components/Icons";

import { BusinessRole, PermissionLevel } from "fe-shared/src/types/domain";

import config from "config";
import { PERMISSIONS, ROUTES } from "consts";

export const ROUTES_MAP_CONFIG = [
  {
    icon: TransactionsIcon,
    label: "pages.transactions",
    path: ROUTES.transactions,
    name: "Transactions",
    host: config.TRANSACTIONS_HOST_URL,
    feature: "Transactions",
    permission: { name: PERMISSIONS.transactions, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
  {
    icon: TransfersIcon,
    label: "pages.transfers",
    path: ROUTES.transfers,
    name: "Transactions",
    host: config.TRANSACTIONS_HOST_URL,
    feature: "Transfers",
    permission: { name: PERMISSIONS.transfers, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
  {
    icon: InvoiceIcon,
    label: "pages.invoices",
    path: ROUTES.invoices,
    name: "Transactions",
    host: config.TRANSACTIONS_HOST_URL,
    feature: "Invoices",
    permission: { name: PERMISSIONS.invoices, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
  {
    icon: PaymentBalanceIcon,
    label: "pages.paymentBalance",
    path: ROUTES.paymentBalance,
    name: "Applications",
    host: config.APPLICATIONS_HOST_URL,
    feature: "PaymentBalance",
    permission: {
      name: PERMISSIONS.paymentBalance,
      level: PermissionLevel.Read,
    },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
  {
    icon: InventoryIcon,
    label: "pages.inventory",
    path: ROUTES.inventory,
    name: "Applications",
    host: config.APPLICATIONS_HOST_URL,
    feature: "Inventory",
    permission: { name: PERMISSIONS.inventory, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
  {
    icon: RequestListIcon,
    label: "pages.requests",
    path: ROUTES.requests,
    name: "Requests",
    host: config.REQUESTS_HOST_URL,
    feature: "Requests",
    permission: { name: PERMISSIONS.requests, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler],
  },
  {
    icon: FolderOpenIcon,
    label: "pages.remittances",
    path: ROUTES.remittances,
    name: "Remittances",
    host: config.REMITTANCES_HOST_URL,
    feature: "Remittances",
    permission: { name: PERMISSIONS.remittances, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Producer],
  },
  {
    icon: UsersIcon,
    label: "pages.employees",
    path: ROUTES.employees,
    name: "Applications",
    host: config.APPLICATIONS_HOST_URL,
    feature: "Employees",
    permission: { name: PERMISSIONS.employees, level: PermissionLevel.Read },
    allowedRoles: [
      BusinessRole.Hauler,
      BusinessRole.Processor,
      BusinessRole.Collector,
      BusinessRole.Producer,
    ],
  },
  {
    icon: FacilityIcon,
    label: "pages.profile",
    path: ROUTES.profile,
    name: "Applications",
    host: config.APPLICATIONS_HOST_URL,
    feature: "Profile",
    permission: { name: PERMISSIONS.profile, level: PermissionLevel.Read },
    allowedRoles: [
      BusinessRole.Hauler,
      BusinessRole.Processor,
      BusinessRole.Collector,
      BusinessRole.Producer,
    ],
  },
  {
    icon: EndMarketIcon,
    label: "pages.endMarket",
    path: ROUTES.endMarket,
    name: "Applications",
    host: config.APPLICATIONS_HOST_URL,
    feature: "EndMarket",
    permission: { name: PERMISSIONS.endMarket, level: PermissionLevel.Read },
    allowedRoles: [BusinessRole.Hauler, BusinessRole.Processor],
  },
];

export type RoutesMapConfig = typeof ROUTES_MAP_CONFIG;
