import React from "react";

import { IconProps } from "../../types/common";

const LogoutIcon = ({
  width,
  height,
  style,
  className,
  fillClassName,
  fillColor,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor!}
      fillRule="nonzero"
      className={fillClassName}
      d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V16C3 15.45 3.45 15 4 15C4.55 15 5 15.45 5 16V18C5 18.55 5.45 19 6 19H18C18.55 19 19 18.55 19 18V6C19 5.45 18.55 5 18 5H6C5.45 5 5 5.45 5 6V8C5 8.55 4.55 9 4 9C3.45 9 3 8.55 3 8V5C3 3.9 3.89 3 5 3ZM15.79 12.7L12.2 16.29C11.81 16.68 11.18 16.68 10.79 16.29C10.41 15.91 10.4 15.27 10.79 14.88L12.67 13H4C3.45 13 3 12.55 3 12C3 11.45 3.45 11 4 11H12.67L10.79 9.11C10.4 8.72 10.4 8.09 10.79 7.7C10.9768 7.51275 11.2305 7.40751 11.495 7.40751C11.7595 7.40751 12.0132 7.51275 12.2 7.7L15.79 11.29C16.18 11.68 16.18 12.31 15.79 12.7Z"
    />
  </svg>
);

LogoutIcon.defaultProps = {
  width: "24",
  height: "24",
  fillColor: "#dadada",
};

export default LogoutIcon;
