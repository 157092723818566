import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { queryCache, useMutation, useQuery, QueryKey } from "react-query";

import {
  ApiError,
  RecordsTemplate,
  RecordsListItem,
  RecordsListProps,
  TransactionRecordsListProps,
  TransactionRecordsListItem,
} from "../../types/common";
import { Transaction, Transfer } from "../../types/domain";
import Tabs from "../Tabs";
import { EditIcon, SupportingDocsIcon } from "../Icons";
import { RecordsList } from "..";
import TransactionRecordsList from "./components/TransactionRecordsList";

import * as api from "./modules/api";

import styles from "./RecordsSidebar.module.scss";

export enum RecordsSidebarTab {
  Activities = "activities",
  Notes = "notes",
  TraceForward = "traceForward",
  TraceBackward = "traceBackward",
}

interface Props {
  objectId?: number | string | null;
  entityType: string;
  searchParams?: string;
  initiatorEmail: string;
  getDialogTitle?: (title: string) => React.ReactNode;
  fileNamePrefix: string;
  fileNameSuffix: string;
  entityName: string;

  canAddNote?: boolean;
  canViewNotes?: boolean;
  canExportNotes?: boolean;
  notesQueryKey?: QueryKey;
  getNotes?: () => Promise<RecordsListItem[]>;
  addNote?: (text: string) => Promise<RecordsListItem>;

  canViewHistory?: boolean;
  canExportHistory?: boolean;
  activitiesQueryKey: QueryKey;

  canViewForwardTraceability?: boolean;
  canViewBackwardTraceability?: boolean;
  forwardTraceabilityQueryKey?: QueryKey;
  backwardTraceabilityQueryKey?: QueryKey;
  getForwardTraceability?: () => Promise<any[]>;
  getBackwardTraceability?: () => Promise<any[]>;
  transactionOrTransfer?: Transaction | Transfer;
}

const RecordsSidebar = (props: Props) => {
  const {
    objectId = null,
    entityType,
    searchParams,
    initiatorEmail,
    getDialogTitle,
    fileNamePrefix,
    fileNameSuffix,
    entityName,
    canAddNote,
    canViewNotes,
    canExportNotes,
    notesQueryKey,
    getNotes,
    addNote,
    canViewHistory,
    canExportHistory,
    activitiesQueryKey,
    canViewForwardTraceability,
    canViewBackwardTraceability,
    forwardTraceabilityQueryKey,
    backwardTraceabilityQueryKey,
    getForwardTraceability,
    getBackwardTraceability,
    transactionOrTransfer,
  } = props;

  const { t } = useTranslation();

  const [notesSearch, setNotesSearch] = useState("");
  const [activitiesSearch, setActivitiesSearch] = useState("");

  const notes = useQuery<RecordsListItem[], AxiosError<ApiError>>(
    notesQueryKey,
    getNotes!,
    {
      enabled: getNotes && canViewNotes,
      refetchOnWindowFocus: false,
    }
  );

  const [addNoteFunc, addNoteStatus] = useMutation<
    RecordsListItem,
    AxiosError<ApiError>,
    string
  >(addNote!, {
    onSuccess: (data) => {
      queryCache.setQueryData(
        notesQueryKey,
        (prevData: RecordsListItem[] = []) => [data, ...prevData]
      );
    },
  });

  const activities = useQuery<RecordsListItem[], AxiosError<ApiError>>(
    activitiesQueryKey,
    () => api.getActivities({ entityType, objectId, searchParams }),
    {
      enabled: canViewHistory,
      refetchOnWindowFocus: false,
    }
  );

  const [addActivity] = useMutation<
    unknown,
    AxiosError<ApiError>,
    api.AddActivityParams
  >(api.addActivity, {
    onSuccess: () => {
      queryCache.invalidateQueries(activitiesQueryKey);
    },
  });

  const prevLinkedBatch = useQuery<
    TransactionRecordsListItem[],
    AxiosError<ApiError>
  >(backwardTraceabilityQueryKey, getForwardTraceability!, {
    enabled: canViewBackwardTraceability,
    refetchOnWindowFocus: false,
  });

  const nextLinkedBatch = useQuery<
    TransactionRecordsListItem[],
    AxiosError<ApiError>
  >(forwardTraceabilityQueryKey, getBackwardTraceability!, {
    enabled: canViewForwardTraceability,
    refetchOnWindowFocus: false,
  });

  const logExportFunc = useCallback(
    (message: string, recordType: api.RecordType) => {
      addActivity({
        message,
        objectId: objectId?.toString() || null,
        entityType,
        initiatorEmail,
        recordType,
      });
    },
    [addActivity, entityType, initiatorEmail, objectId]
  );

  const commonProps = {
    getDialogTitle,
    fileNamePrefix,
    fileNameSuffix,
    entityName,
  };

  const tabs = useMemo(
    () =>
      [
        {
          id: 0,
          label: t(`${RecordsSidebarTab.Activities}.title`),
          canView: canViewHistory,
          tabProps: {
            translationPrefix: RecordsSidebarTab.Activities,
            queryResult: activities,
            canExport: canExportHistory,
            search: activitiesSearch,
            setSearch: setActivitiesSearch,
            iconComponent: SupportingDocsIcon,
            logExport: () =>
              logExportFunc(
                t("activities.export.logMessage"),
                api.RecordType.historyRecords
              ),
            columnConfig: [
              {
                getOriginalValue: (i: any) => i.createdDate,
                mappedKey: t("activities.export.createdDate"),
                width: 20,
              },
              {
                getOriginalValue: (i: any) => i.createdBy,
                mappedKey: t("activities.export.createdBy"),
                width: 30,
              },
              {
                getOriginalValue: (i: any) => i.text,
                mappedKey: t("activities.export.text"),
                width: 100,
              },
            ],
          },
        },
        {
          id: 1,
          label: t(`${RecordsSidebarTab.Notes}.title`),
          canView: canViewNotes,
          tabProps: {
            translationPrefix: RecordsSidebarTab.Notes,
            queryResult: notes,
            canExport: canExportNotes,
            canAdd: canAddNote,
            search: notesSearch,
            setSearch: setNotesSearch,
            addRecord: addNoteFunc,
            addRecordStatus: addNoteStatus,
            logExport: () =>
              logExportFunc(t("notes.export.logMessage"), api.RecordType.notes),
            iconComponent: EditIcon,
            columnConfig: [
              {
                getOriginalValue: (i: any) => i.createdDate,
                mappedKey: t("notes.export.createdDate"),
                width: 20,
              },
              {
                getOriginalValue: (i: any) => i.text,
                mappedKey: t("notes.export.text"),
                width: 100,
              },
              {
                getOriginalValue: (i: any) => i.createdBy,
                mappedKey: t("notes.export.createdBy"),
                width: 30,
              },
            ],
          },
        },
        {
          id: 2,
          label: t(`${RecordsSidebarTab.TraceBackward}.title`),
          canView: canViewBackwardTraceability,
          contentTemplate: RecordsTemplate.LinkedBatch,
          tabProps: {
            translationPrefix: RecordsSidebarTab.TraceBackward,
            queryResult: prevLinkedBatch,
            iconComponent: SupportingDocsIcon,
            transactionOrTransfer,
          },
        },
        {
          id: 3,
          label: t(`${RecordsSidebarTab.TraceForward}.title`),
          canView: canViewForwardTraceability,
          contentTemplate: RecordsTemplate.LinkedBatch,
          tabProps: {
            translationPrefix: RecordsSidebarTab.TraceForward,
            queryResult: nextLinkedBatch,
            iconComponent: SupportingDocsIcon,
            transactionOrTransfer,
          },
        },
      ].filter((tab) => tab.canView),
    [
      activities,
      activitiesSearch,
      addNoteFunc,
      addNoteStatus,
      canAddNote,
      canExportHistory,
      canExportNotes,
      canViewBackwardTraceability,
      canViewForwardTraceability,
      canViewHistory,
      canViewNotes,
      logExportFunc,
      nextLinkedBatch,
      notes,
      notesSearch,
      prevLinkedBatch,
      t,
      transactionOrTransfer,
    ]
  );

  const [activeTabId, setActiveTabId] = useState(tabs[0]?.id);

  const activeTab = useMemo(() => {
    const foundTab = tabs.find((tab) => tab.id === activeTabId);

    return foundTab ?? tabs[0];
  }, [activeTabId, tabs]);

  useEffect(() => {
    const foundTab = tabs.find((tab) => tab.id === activeTabId);

    if (!foundTab) {
      setActiveTabId(tabs[0]?.id);
    }
  }, [tabs, activeTabId]);

  return (
    <aside className={styles.recordsSidebar}>
      {tabs.length > 1 ? (
        <Tabs
          items={tabs}
          activeTabId={activeTabId}
          onChange={setActiveTabId}
          className={styles.recordsSidebarTabs}
        />
      ) : (
        <h4 className={styles.recordsSidebarTitle}>{activeTab!.label}</h4>
      )}
      {activeTab?.contentTemplate === RecordsTemplate.LinkedBatch ? (
        <TransactionRecordsList
          key={activeTabId}
          {...((activeTab!.tabProps as unknown) as TransactionRecordsListProps)}
          {...commonProps}
        />
      ) : (
        <RecordsList
          key={activeTabId}
          {...((activeTab!.tabProps as unknown) as RecordsListProps)}
          {...commonProps}
        />
      )}
    </aside>
  );
};

export default RecordsSidebar;
