import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { NotFoundPage } from "fe-shared/src/components";

import { ROUTES } from "consts";

export enum ErrorReasons {
  Invalid = "invalid",
  Expired = "expired",
  Used = "used",
}

const BrokenLink = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { reason } = useParams<{
    reason: ErrorReasons;
  }>();

  if (!Object.values(ErrorReasons).includes(reason)) {
    history.push(`${ROUTES.brokenLink}/${ErrorReasons.Invalid}`);
  }

  return (
    <NotFoundPage
      returnLink={ROUTES.root}
      title={t("pageBrokenLink.title")}
      descriptions={[t(`pageBrokenLink.${reason}`)]}
    />
  );
};

export default BrokenLink;
