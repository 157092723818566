type ApplicationConfig = {
  [key: string]: string;
};

interface WindowWithAppConfig extends Window {
  APP_CONFIG?: ApplicationConfig;
}

const { APP_CONFIG = {} } = window as WindowWithAppConfig;

const DEFAULT_CONFIG: ApplicationConfig = {
  API_HOST_URL: "https://webappgateway.dev.etrackssrp.com/",
  APPLICATIONS_HOST_URL: "https://localhost:4001/",
  TRANSACTIONS_HOST_URL: "https://localhost:4002/",
  REQUESTS_HOST_URL: "https://localhost:4003/",
  REMITTANCES_HOST_URL: "https://localhost:4004/",
  MOBILE_APP_DOWNLOAD_URL: "https://dist-etracks-srp.netlify.app/",
  HELP_PORTAL_URL_PRODUCER: "https://producer-webapp.dev.etrackssrp.com/",
  HELP_PORTAL_URL_COLLECTOR: "https://collector-webapp.dev.etrackssrp.com/",
  HELP_PORTAL_URL_PROCESSOR: "https://processor-webapp.dev.etrackssrp.com/",
  HELP_PORTAL_URL_HAULER: "https://hauler-webapp.dev.etrackssrp.com/",
  SENTRY_DSN: "",
  SENTRY_ENV: "",
};

export default {
  ...DEFAULT_CONFIG,
  ...APP_CONFIG,
};
