import React, { useRef, useLayoutEffect } from "react";
import classnames from "classnames";

import {
  InfoIcon,
  WarningIcon,
  CloseIcon,
  ErrorIcon,
  SuccessIcon,
} from "../Icons";

import styles from "./Banner.module.scss";

interface Props {
  id: string;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onDismiss?: () => void;
  variant?: "info" | "success" | "warning" | "error";
  isScrolledIntoView?: boolean;
}

const ICONS_MAP = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const Banner = (props: Props) => {
  const {
    id,
    title,
    variant = "info",
    children,
    className,
    onDismiss,
    isScrolledIntoView = true,
  } = props;
  const Icon = ICONS_MAP[variant];
  const bannerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (bannerRef.current && isScrolledIntoView) {
      bannerRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [isScrolledIntoView]);

  return (
    <div
      id={id}
      ref={bannerRef}
      aria-live="polite"
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-content`}
      className={classnames(
        styles.banner,
        styles[`banner${variant}`],
        className
      )}
      role={variant === "success" || variant === "info" ? "status" : "alert"}
    >
      <div className={styles.bannerIcon}>
        <Icon />
      </div>
      <div>
        <div
          id={`${id}-title`}
          className={classnames(
            styles.bannerTitle,
            !children && styles.bannerTitleOnly
          )}
        >
          {title}
        </div>
        {!!children && (
          <div
            id={`${id}-content`}
            className={classnames(
              styles.bannerContent,
              !title && styles.bannerContentOnly
            )}
          >
            {children}
          </div>
        )}
      </div>
      {!!onDismiss && (
        <button
          type="button"
          onClick={onDismiss}
          className={styles.bannerCloseBtn}
          aria-label="Dismiss notification"
        >
          <CloseIcon fillColor={null} />
        </button>
      )}
    </div>
  );
};

export default Banner;
