import React, { useMemo } from "react";
import classnames from "classnames";

import { hexToRgb } from "../../helpers/colors";

import styles from "./Loader.module.scss";

interface Props {
  color?: string;
  isSlim?: boolean;
  className?: string;
  fontSize?: number | string;
  isBlocking?: boolean;
  isFullScreen?: boolean;
}

const Loader = (props: Props) => {
  const {
    fontSize = 40,
    isBlocking,
    isFullScreen,
    isSlim = true,
    className,
    color,
  } = props;

  const spinnerStyles = useMemo(() => {
    const borderTopColor =
      color ||
      getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-color")
        .trim();
    const border = `${isSlim ? ".1em" : ".15em"} solid rgba(${hexToRgb(
      borderTopColor
    )}, 0.25)`;

    return {
      border,
      fontSize,
      borderTopColor,
    };
  }, [color, isSlim, fontSize]);

  return (
    <div
      className={classnames(styles.etLoader, className, {
        [styles.etLoaderBlocking]: isBlocking,
        [styles.etLoaderFullscreen]: isFullScreen,
      })}
    >
      <div style={spinnerStyles} className={styles.etLoaderSpinner} />
    </div>
  );
};

export default Loader;
