import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useMutation, queryCache } from "react-query";

import { Company, InviteStatuses } from "fe-shared/src/types/domain";
import { Loader, showNotification } from "fe-shared/src/components";
import { CheckIcon } from "fe-shared/src/components/Icons";
import * as api from "../../routes/ExternalApps/modules/api";
import { companiesQueryKey } from "../../consts";
import styles from "./CompanyLink.module.scss";

export interface Props {
  company: Company;
  onClick?: () => void;
  dropdownView?: boolean;
}

const CompanyLink = (props: Props) => {
  const { t } = useTranslation();
  const {
    company: { companyId, invitationStatus, regNumber, displayName },
    onClick = () => {},
    dropdownView,
  } = props;
  const isInvitation = invitationStatus === InviteStatuses.Invited;

  const [acceptInvitation, { isLoading }] = useMutation(
    () => api.acceptInvitation(companyId),
    {
      throwOnError: true,
      onSuccess: () => {
        queryCache.setQueryData(companiesQueryKey, (prevData?: Company[]) =>
          prevData
            ? prevData.map((c) =>
                c.companyId === companyId
                  ? {
                      ...c,
                      invitationStatus: InviteStatuses.Accepted,
                    }
                  : c
              )
            : prevData!
        );
        showNotification(t("chooseCompany.acceptInvitationToast"));
      },
    }
  );

  const onButtonClick = () => {
    if (isInvitation) {
      acceptInvitation();
    } else {
      onClick();
    }
  };

  return (
    <button
      type="button"
      id={`chose-company-button-${companyId}`}
      className={classnames(styles.companyLinkContainer, {
        [styles.dropdownView]: dropdownView,
      })}
      onClick={onButtonClick}
      disabled={isLoading}
    >
      <div
        className={classnames(styles.companyLinkContent, {
          [styles.companyLinkContentIsInvited]: isInvitation,
        })}
      >
        <span>{regNumber}</span>
        <span>{displayName}</span>
      </div>
      {isInvitation &&
        (isLoading ? (
          <Loader className={styles.companyLinkLoader} fontSize={28} />
        ) : (
          <div className={styles.companyLinkIcon}>
            <CheckIcon fillColor="#27A83B" />
            <span>{t("chooseCompany.join")}</span>
          </div>
        ))}
    </button>
  );
};

export default CompanyLink;
