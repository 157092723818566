import * as Sentry from "@sentry/react";

interface WindowWithAppConfig extends Window {
  APP_CONFIG?: {
    [key: string]: string;
  };
}

export const isFingerprintMatched = (
  matchesList: string[][],
  fingerprint?: string[]
) =>
  matchesList.some((matches) => matches.every((m) => fingerprint?.includes(m)));

export const isIgnoredEvent = (fingerprint?: string[]) =>
  isFingerprintMatched(
    [
      ["batches/getTransactionBatch", "404"],
      ["employees/getEmployee", "404"],
      ["users/validateEmail", "404"],
      ["account/login", "401"],
      ["account/login", "404"],
      ["account/login", "422"],
      ["account/refreshToken", "401"],
      ["ExternalAccount/refreshtoken", "401"],
      ["401"],
      ["account/resetPassword"],
    ],
    fingerprint
  );

export const isInfoEvent = (fingerprint?: string[]) =>
  isFingerprintMatched(
    [
      ["account/validateActionToken", "400"],
      ["account/validateActionToken", "422"],
      ["account/generateActionToken", "422"],
      ["account/generateActionToken", "400"],
      ["transactions/invalidate", "422"],
      ["422"],
    ],
    fingerprint
  );

export const beforeSend = (event: Sentry.Event) => {
  const ignoredEnvironments = ["development", "dev", ""];
  const { APP_CONFIG = {} } = window as WindowWithAppConfig;

  if (
    isIgnoredEvent(event.fingerprint) ||
    ignoredEnvironments.includes(event.environment || "") ||
    ignoredEnvironments.includes(APP_CONFIG?.SENTRY_ENV || "")
  ) {
    return null;
  }

  if (isInfoEvent(event.fingerprint)) {
    event.level = "info";
  }

  if (
    isFingerprintMatched([["400"], ["500"]], event.fingerprint) &&
    event.exception?.values?.[0]?.value
  ) {
    event.exception.values[0].value = event.fingerprint?.join(" - ");
  }
  return event;
};
