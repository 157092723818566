import React from "react";

import { IconProps } from "../../types/common";

const ArrowRight = ({
  width,
  height,
  style,
  className,
  fillClassName,
  fillColor,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor!}
      fillRule="nonzero"
      className={fillClassName}
      d="M9.29 15.88L13.17 12 9.29 8.12a.996.996 0 111.41-1.41l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3a.996.996 0 01-1.41 0c-.38-.39-.39-1.03 0-1.42z"
    />
  </svg>
);

ArrowRight.defaultProps = {
  width: "24",
  height: "24",
  fillColor: "#fff",
};

export default ArrowRight;
