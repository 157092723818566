import React from "react";

import { IconProps } from "../../types/common";

const CheckIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "white",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="-4 -4 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M5.79506 10.875L2.32506 7.40501C2.13823 7.21776 1.88458 7.11252 1.62006 7.11252C1.35554 7.11252 1.10189 7.21776 0.915059 7.40501C0.525059 7.79501 0.525059 8.42501 0.915059 8.81501L5.09506 12.995C5.48506 13.385 6.11506 13.385 6.50506 12.995L17.0851 2.41501C17.4751 2.02501 17.4751 1.39501 17.0851 1.00501C16.8982 0.817757 16.6446 0.712524 16.3801 0.712524C16.1155 0.712524 15.8619 0.817757 15.6751 1.00501L5.79506 10.875Z"
    />
  </svg>
);

export default CheckIcon;
