import React, { ButtonHTMLAttributes, forwardRef } from "react";
import classnames from "classnames";

import { handleMouseDown } from "../../helpers/buttonHandlers";

import ButtonContent from "./ButtonContent";

import { ButtonVariant, ButtonIconPosition, ButtonSize } from "./types";

import styles from "./Button.module.scss";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  variant?: ButtonVariant;
  iconPosition?: ButtonIconPosition;
  iconComponent?: React.ElementType;
  size?: ButtonSize;
}

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className,
    variant = "filled",
    size = "large",
    iconPosition,
    iconComponent,
    onMouseDown,
    ...otherProps
  } = props;

  return (
    <button
      ref={ref}
      type="button"
      onMouseDown={(e) => handleMouseDown(e, onMouseDown)}
      className={classnames(
        styles.button,
        styles[size],
        styles[variant],
        className
      )}
      {...otherProps}
    >
      <ButtonContent iconPosition={iconPosition} iconComponent={iconComponent}>
        {children}
      </ButtonContent>
    </button>
  );
});

export default Button;
