import React from "react";

import { IconProps } from "../../types/common";

const InventoryIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M5.5 10.327v9c0 .55-.45 1-1 1h-.202c-.55 0-1-.45-1-1v-9c-.46 0-.67-.57-.33-.87l8.36-5.53c.38-.34.96-.34 1.34 0l8.36 5.53c.35.3.13.87-.33.87v9c0 .55-.45 1-1 1H19.5c-.55 0-1-.45-1-1v-9h-13zm2 2.173A.5.5 0 018 12h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1zm0 3A.5.5 0 018 15h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1zm0 3A.5.5 0 018 18h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1zm5 0a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1zm0-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1z"
    />
  </svg>
);

export default InventoryIcon;
