import { forwardRef, useImperativeHandle, useState } from "react";
import { IDoesFilterPassParams, IFilterParams } from "@ag-grid-community/core";
import { DataGridDateRangeFilter } from "../modules/types";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

type DateRangeValues = [string | null, string | null];
interface Props extends IFilterParams {
  doesFilterPass?: (
    value: DateRangeValues,
    params: IDoesFilterPassParams
  ) => boolean;
}

export default forwardRef((props: Props, ref) => {
  const { doesFilterPass } = props;
  const initialDate: DateRangeValues = [null, null];
  const [dateRange, setDateRange] = useState<DateRangeValues>(initialDate);

  useUpdateEffect(() => {
    const timeout = setTimeout(() => {
      props.filterChangedCallback();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [dateRange, props.filterChangedCallback]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive: () => !!dateRange?.length,
      doesFilterPass: (params: IDoesFilterPassParams) => {
        if (doesFilterPass) {
          return doesFilterPass(dateRange, params.data);
        }
        return true;
      },
      getModel: () => ({ type: "dateRangeFilter", value: dateRange }),
      setModel: (model: DataGridDateRangeFilter) =>
        setDateRange(model?.value || initialDate),
    };
  });

  return null;
});
