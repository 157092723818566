import React from "react";

import { IconProps } from "../../types/common";

const InvisibilityIcon = ({
  width = 24,
  height = 24,
  style,
  className,
  fillClassName,
  fillColor = "#B5BDC2",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor!}
      fillRule="evenodd"
      className={fillClassName}
      transform="translate(0,0.5)"
      d="M2.71 4.54a1 1 0 010-1.41 1 1 0 011.42 0l16.31 16.33a1 1 0 11-1.41 1.41l-2.72-2.72A11.83 11.83 0 011 11.47a11.9 11.9 0 013.68-4.96L2.71 4.54zM17 11.47a5 5 0 00-6.47-4.76L8.36 4.54A11.85 11.85 0 0123 11.46a11.8 11.8 0 01-3.18 4.53l-3.06-3.06c.14-.46.24-.95.24-1.46zm-5 5a5 5 0 01-4.51-7.14l1.57 1.57a3 3 0 003.51 3.5l1.57 1.57c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
    />
  </svg>
);

export default InvisibilityIcon;
