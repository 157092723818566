import React from "react";

import { IconProps } from "../../types/common";

import ArrowRight from "./ArrowRight";

const ArrowLeft = ({ style = {}, ...otherProps }: IconProps) => (
  <ArrowRight
    style={{
      ...style,
      transform: "rotate(180deg)",
    }}
    {...otherProps}
  />
);

export default ArrowLeft;
