import { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { Company } from "fe-shared/src/types/domain";

import { ApiError } from "fe-shared/src/types/common";

import UserContext from "contexts/userContext";

import { ROUTES } from "consts";
import { ExternalUserInfo } from "types";

export interface LoginParams {
  email: string;
  password: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export const login = async ({
  email,
  password,
}: LoginParams): Promise<ExternalUserInfo> => {
  const {
    data: { accessToken, refreshToken },
  } = await axios.post<LoginResponse>(
    "account/login",
    {
      password,
      username: email,
    },
    { hideToast: true }
  );

  const { data: companies } = await axios.get<Company[]>(
    "/companies/userCompanies",
    {
      headers: { Authorization: `Bearer ${accessToken}` },
      hideToast: true,
      withCredentials: true,
    }
  );

  if (!companies.length) {
    throw {
      code: "AccountNoLongerActive",
      message: "errors.ContactAdministrator",
    } as unknown;
  }

  return {
    token: accessToken,
    refreshToken,
  };
};

export const useLogin = (isKeepLoginChecked = true, isRedirected = true) => {
  const history = useHistory();
  const { setData, setIsKeepLoginChecked } = useContext(UserContext);

  const mutation = useMutation<
    ExternalUserInfo | null,
    AxiosError<ApiError>,
    LoginParams
  >(login, {
    onSuccess: (data) => {
      setIsKeepLoginChecked(isKeepLoginChecked);
      setData(data);
      if (isRedirected) {
        history.replace(ROUTES.root);
      }
    },
    onError: () => {
      if (isRedirected) {
        history.replace(ROUTES.login);
      }
    },
  });

  return mutation;
};

export default useLogin;
