import React, {
  TextareaHTMLAttributes,
  InputHTMLAttributes,
  useMemo,
  ChangeEvent,
  forwardRef,
  RefObject,
} from "react";
import InputMask from "react-input-mask";
import classnames from "classnames";

import { FormControlLabel, FormControlMessage } from "..";

import styles from "./TextInput.module.scss";

interface CommonProps {
  id: string;
  type?: string;
  title?: React.ReactNode;
  errorText?: string;
  hintText?: string;
  isError?: boolean;
  inputClassName?: string;
  wrapperClassName?: string;
  messageClassName?: string;
  labelClassName?: string;
  width?: string | number;
  inline?: boolean;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string
  ) => void;
  transformValue?: (value: string) => string;
  tooltipText?: string;
  mask?: string;
  inputIconContent?: React.ReactNode;
  isMultiline?: boolean;
}

export type Props = CommonProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "title" | "onChange"> &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "title" | "onChange">;

const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      id,
      title,
      type,
      required,
      errorText,
      hintText,
      isError,
      inputClassName,
      wrapperClassName,
      messageClassName,
      labelClassName,
      mask,
      width,
      inline,
      transformValue,
      tooltipText,
      onChange,
      inputIconContent,
      isMultiline,
      ...otherProps
    } = props;
    const InputElement = isMultiline ? "textarea" : "input";
    const showErrorMessage = !!errorText && isError;
    const showHintText = !showErrorMessage && hintText;
    const describedBy = useMemo(() => {
      if (showErrorMessage) {
        return `${id}-error-msg`;
      }
      if (showHintText) {
        return `${id}-hint-msg`;
      }
      return undefined;
    }, [id, showErrorMessage, showHintText]);

    const onInputChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) =>
      onChange(
        e,
        transformValue ? transformValue(e.target.value) : e.target.value
      );

    const inputProps = {
      id,
      required,
      type: type || "text",
      spellCheck: false,
      autoComplete: "off",
      "aria-invalid": !!isError,
      "aria-describedby": describedBy,
      className: classnames(inputClassName, styles.textInputDefault, {
        [styles.passwordType]: inputIconContent,
        [styles.textInputMultiline]: isMultiline,
      }),
      onChange: onInputChange,
      ...otherProps,
    };

    return (
      <div
        className={classnames(
          {
            [styles.textInputWrapper]: true,
            [styles.textInputInline]: inline,
          },
          wrapperClassName
        )}
        style={{
          width,
        }}
      >
        <FormControlLabel
          id={id}
          title={title}
          tooltipText={tooltipText}
          required={required}
          className={labelClassName}
        />
        <div className={styles.textInputFieldWrapper}>
          {mask ? (
            <InputMask mask={mask} maskChar={null} {...inputProps} />
          ) : (
            <InputElement
              ref={
                ref as RefObject<HTMLTextAreaElement> &
                  RefObject<HTMLInputElement>
              }
              {...inputProps}
            />
          )}
          {inputIconContent}
        </div>
        <FormControlMessage
          id={id}
          errorText={errorText}
          hintText={hintText}
          isError={isError}
          className={messageClassName}
        />
      </div>
    );
  }
);

export default TextInput;
