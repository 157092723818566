import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useParams, useHistory } from "react-router-dom";

import LoginRequirements from "components/LoginRequirements";
import PasswordErrorBanner, {
  PasswordApiError,
} from "components/PasswordErrorBanner";
import {
  Button,
  PasswordInput,
  Loader,
  Checkbox,
  LogoLayout,
} from "fe-shared/src/components";
import UserContext from "contexts/userContext";

import useLogin from "hooks/useLogin";
import useValidation from "fe-shared/src/hooks/useValidation";
import {
  getTokenErrorInfo,
  getNewUserRedirectRoute,
} from "helpers/tokenErrors";

import * as api from "api/password";
import { getTokenInformation } from "api/token";

import { ROUTES } from "consts";
import { TokenErrorCodes, TokenType } from "types";

import { FORM_CONFIGURATION, errorMessages } from "./modules/config";

import styles from "./CreatePassword.module.scss";

const CreatePassword = () => {
  const [isKeepLoginChecked, setIsKeepLoginChecked] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { user, setData } = useContext(UserContext);
  const { validationConfig, initialContent } = FORM_CONFIGURATION;

  const { values, errors, updateValue, validateValue, isValid } = useValidation(
    validationConfig,
    initialContent,
    errorMessages
  );

  const { createPasswordToken } = useParams<{
    createPasswordToken: string;
  }>();

  const { data: userEmail, isLoading: isInitialLoading } = useQuery<
    string,
    AxiosError<PasswordApiError>
  >(
    ["createPasswordEmail", createPasswordToken, TokenType.CreatePassword],
    getTokenInformation,
    {
      onError: (error) => {
        const { email, tokenErrorCode } = getTokenErrorInfo(error);
        const isSameUserLoggedIn = email === user?.data?.email;
        const isTokenUsed = tokenErrorCode === TokenErrorCodes.TokenReusage;

        if (isTokenUsed && isSameUserLoggedIn) {
          history.replace(ROUTES.root);
        } else if (isTokenUsed && !isSameUserLoggedIn) {
          setData(null);
          history.replace(ROUTES.accountCreated);
        } else {
          history.replace(
            getNewUserRedirectRoute(error, createPasswordToken, true)
          );
        }
      },
    }
  );

  const [login, mutationResultLogin] = useLogin(isKeepLoginChecked);

  const [createPassword, mutationResultReset] = useMutation<
    api.SetPasswordResponse,
    AxiosError<PasswordApiError>,
    api.SetPasswordParams
  >(api.setPassword, {
    onSuccess: () => {
      login({
        email: userEmail!,
        password: values.password,
      });
    },
  });

  const mutationResult = mutationResultReset.isError
    ? mutationResultReset
    : mutationResultLogin;
  const { isError } = mutationResult;
  const isLoading =
    mutationResultReset.isLoading || mutationResultLogin.isLoading;

  const tryCreatePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValid) {
      createPassword({
        ...values,
        token: createPasswordToken,
        email: userEmail!,
        isFirstLogin: true,
      });
    }
  };

  if (isInitialLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      <h1>{t("login.welcome")}</h1>
      <p className={styles.etCreatePasswordDescription}>
        {t("passwordActions.loginEmail")}
      </p>
      <span className={styles.etCreatePasswordInfo}>{userEmail}</span>
      {isError && <PasswordErrorBanner requestStatus={mutationResult} />}
      <form
        onSubmit={tryCreatePassword}
        aria-label={t("login.form")}
        className={styles.etCreatePasswordControls}
      >
        <input
          type="email"
          id="hidden-input"
          className={styles.etCreatePasswordHiddenInput}
          value={userEmail}
        />
        <PasswordInput
          name="password"
          id="password-input"
          autoComplete="off"
          title={t("createPassword")}
          value={values.password}
          isError={!!errors.password}
          errorText={errors.password}
          onBlur={() => validateValue("password")}
          inputClassName={styles.etCreatePasswordInput}
          onChange={(e) => updateValue("password", e.target.value)}
        />
        <PasswordInput
          name="confirmedPassword"
          id="confirmed-password-input"
          autoComplete="off"
          title={t("confirmPassword")}
          value={values.confirmedPassword}
          isError={!!errors.confirmedPassword}
          errorText={errors.confirmedPassword}
          inputClassName={styles.etCreatePasswordInput}
          onBlur={() => validateValue("confirmedPassword")}
          onChange={(e) => updateValue("confirmedPassword", e.target.value)}
        />
        <div className={styles.etCreatePasswordBottomLine}>
          <Checkbox
            id="keep-loggedin"
            title={t("login.keepMeLoggedin")}
            onChange={(e, v) => setIsKeepLoginChecked(v)}
            value={isKeepLoginChecked}
          />
          <Button
            type="submit"
            id="start-application-button"
            disabled={
              !values.confirmedPassword ||
              !values.password ||
              !!Object.keys(errors).length ||
              isError
            }
          >
            {t("login.button")}
          </Button>
        </div>
      </form>
      <LoginRequirements />
    </LogoLayout>
  );
};

export default CreatePassword;
