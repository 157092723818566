import { Joi, passwordJoiSchema } from "fe-shared/src/helpers/validation";
import { CREATE_PASSWORD_ERROR_MESSAGES } from "fe-shared/src/helpers/errorMessages";

import { ErrorMessages, FormConfiguration } from "fe-shared/src/types/common";

interface FormValues {
  password: string;
  confirmedPassword: string;
}

export const errorMessages: ErrorMessages = {
  ...CREATE_PASSWORD_ERROR_MESSAGES,
};

const initialContent = {
  password: "",
  confirmedPassword: "",
};

const validationConfig = Joi.object({
  password: passwordJoiSchema,
  confirmedPassword: Joi.string().valid(Joi.ref("password")).required(),
});

export const FORM_CONFIGURATION: FormConfiguration<FormValues> = {
  initialContent,
  validationConfig,
};
