import { createContext } from "react";

import { SocketContextData } from "../types/common";

const SocketContext = createContext<SocketContextData>({
  isConnected: false,
  socketUrl: null,
  connect: () => Promise.resolve(),
  disconnect: () => {},
  listen: () => {},
  unlisten: () => {},
});

export default SocketContext;
