import { ErrorMessages } from "../types/common";

export const EMAIL_ERROR_MESSAGES: ErrorMessages = {
  email: {
    "string.empty": "validation.emailRequired",
    "string.email": "validation.emailInvalid",
  },
};

export const CREATE_PASSWORD_ERROR_MESSAGES: ErrorMessages = {
  password: {
    "string.empty": "validation.passwordRequired",
    "string.pattern.base": "validation.passwordNotMatchRequirements",
  },
  confirmedPassword: {
    "string.empty": "validation.passwordRequired",
    "any.only": "validation.passwordNotMatch",
  },
};

export const INVALID_FILE_ERROR_MESSAGE = "hasInvalidFile";
