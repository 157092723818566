import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, LogoLayout } from "fe-shared/src/components";
import UserContext from "contexts/userContext";
import { Company, InviteStatuses } from "fe-shared/src/types/domain";
import CompanyLink from "../CompanyLink";
import * as api from "../UserProfile/modules/api";

import styles from "./ChooseCompanyPage.module.scss";

export interface Props {
  companies: Company[];
  onChooseCompany: (company: Company) => void;
  isInactiveEmployee: boolean;
  lastVisitedCompanyId?: number;
  lastVisitedCompanyName?: string;
}

const ChooseCompanyPage = (props: Props) => {
  const { t } = useTranslation();
  const {
    companies,
    onChooseCompany,
    isInactiveEmployee,
    lastVisitedCompanyId,
    lastVisitedCompanyName = "",
  } = props;

  const { setData } = useContext(UserContext);

  const acceptedCompanies = companies.filter(
    (item) => item.invitationStatus === InviteStatuses.Accepted
  );

  const invitedCompanies = companies.filter(
    (item) => item.invitationStatus === InviteStatuses.Invited
  );

  const isCompanyUnavailable =
    !!lastVisitedCompanyId &&
    !acceptedCompanies.find((c) => c.companyId === lastVisitedCompanyId);

  const chooseTitle = useMemo(() => {
    if (!isInactiveEmployee) {
      return t("chooseCompany.title");
    }
    return `${t(
      `chooseCompany.inactiveUser.${
        isCompanyUnavailable ? "noAccessTitle" : "limitedAccessTitle"
      }`,
      { companyName: lastVisitedCompanyName }
    )}`;
  }, [t, isInactiveEmployee, isCompanyUnavailable, lastVisitedCompanyName]);

  const onLogout = () => {
    api.userLogout().then(() => setData(null));
  };

  return (
    <LogoLayout>
      <h2 className={styles.header}>{chooseTitle}</h2>
      <div className={styles.companiesMenuContainer}>
        {!!acceptedCompanies.length && isCompanyUnavailable && (
          <span className={styles.companiesMenuContainerListName}>
            {t("chooseCompany.inactiveUser.switchCompany")}
          </span>
        )}
        {acceptedCompanies.map((item) => (
          <CompanyLink
            key={item.companyId}
            company={item}
            onClick={() => onChooseCompany(item)}
          />
        ))}
      </div>
      {!!invitedCompanies.length && (
        <>
          <p className={styles.companiesMenuInvitationsHeader}>
            {invitedCompanies.length > 1
              ? t("chooseCompany.manyInvitationsHeader")
              : t("chooseCompany.invitationHeader")}
          </p>
          <div className={styles.companiesMenuContainer}>
            {invitedCompanies.map((item) => (
              <CompanyLink key={item.companyId} company={item} />
            ))}
          </div>
        </>
      )}
      {isInactiveEmployee && !acceptedCompanies.length && (
        <Button
          id="no-companies-user-logout-button"
          variant="outlined"
          onClick={onLogout}
        >
          {t("chooseCompany.logout")}
        </Button>
      )}
    </LogoLayout>
  );
};

export default ChooseCompanyPage;
