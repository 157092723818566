import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import {
  Loader,
  FormControlLabel,
  FormControlMessage,
  FormControlDropdown,
} from "..";

import useDropdown from "../../hooks/useDropdown";
import useElementSize from "../../hooks/useElementSize";

import styles from "./SelectInput.module.scss";

interface Option {
  label: string;
  value: string | number;
}

export interface Props {
  id: string;
  options?: Option[];
  width?: string | number;
  required?: boolean;
  title?: React.ReactNode;
  tooltipText?: string;
  hintText?: string;
  value: string | number;
  wrapperClassName?: string;
  messageClassName?: string;
  labelClassName?: string;
  errorText?: string;
  isError?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onChange: (event: React.SyntheticEvent, value: unknown) => void;
  onBlur?: () => void;
}

const defaultProps = {
  options: [],
};

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

const SelectInput = (props: Props) => {
  const {
    id,
    title,
    tooltipText,
    required,
    options = defaultProps.options,
    wrapperClassName,
    labelClassName,
    messageClassName,
    width,
    onChange,
    onBlur,
    value,
    errorText,
    isError,
    hintText,
    placeholder,
    disabled,
    isLoading,
  } = props;
  const isDisabled = disabled || isLoading;

  const ref = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLUListElement>(null);
  const tippyRef = useRef<any>(null);
  const { width: minWidth } = useElementSize(ref);

  const {
    label,
    selectedIdx,
    isDropdownOpened,
    onKeyDown,
    changeDropdownState,
  } = useDropdown(ref, optionsWrapperRef, options, onChange, value);

  const updateTippy = useCallback(() => {
    setTimeout(() => {
      tippyRef?.current?._tippy?.popperInstance?.update();
    });
  }, [tippyRef]);

  useEffect(() => {
    window.addEventListener("resize", updateTippy);

    return () => {
      window.removeEventListener("resize", updateTippy);
    };
  }, [updateTippy]);

  const onValueChange = (
    e: React.SyntheticEvent,
    newValue: number | string
  ) => {
    if (newValue !== value) {
      onChange(e, newValue);
    }

    changeDropdownState(false);
  };

  const tooltipContent = (
    <FormControlDropdown
      wrapperRef={optionsWrapperRef}
      value={value}
      selectedIdx={selectedIdx}
      options={options}
      onChange={onValueChange}
      getOption={(option) => option.label}
      minWidth={minWidth}
    />
  );

  return (
    <div
      style={{ width }}
      className={classnames(styles.selectWrapper, wrapperClassName)}
    >
      <FormControlLabel
        id={id}
        title={title}
        tooltipText={tooltipText}
        required={required}
        className={labelClassName}
      />
      <Tippy
        interactive
        ref={tippyRef}
        arrow={false}
        offset={[0, 2]}
        placement="bottom-start"
        content={isDropdownOpened ? tooltipContent : null}
        popperOptions={popperOptions}
        className={styles.selectTooltip}
        visible={isDropdownOpened}
        appendTo={() => document.body}
      >
        <div
          id={id}
          className={classnames({
            [styles.select]: true,
            [styles.selectEmpty]: !value,
            [styles.selectError]: isError,
            [styles.disabled]: isDisabled,
          })}
          ref={ref}
          role="button"
          tabIndex={isDisabled ? null! : 0}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onClick={() => !isDisabled && changeDropdownState()}
        >
          {isLoading && (
            <span className={styles.selectLoader}>
              <Loader fontSize={24} />
            </span>
          )}
          {value ? label : placeholder}
        </div>
      </Tippy>
      <FormControlMessage
        id={id}
        errorText={errorText}
        hintText={hintText}
        isError={isError}
        className={messageClassName}
      />
    </div>
  );
};

export default SelectInput;
