export enum TransferType {
  IntoProcessing = "IntoProcessing",
  OutOfProcessing = "OutOfProcessing",
  DeliveryToStorage = "DeliveryToStorage",
  PickupFromStorage = "PickupFromStorage",
  NonEligibleWriteOff = "NonElMaterialWriteOff",
  NonEligibleBufferWriteOff = "NonElMaterialWriteOff2",
}

export interface Transfer {
  transferId: number;
  transferNumber: string;
  completedDate: string;
  submittedDate: string;
  initiatedDate: string;
  assignedToDisplayName: string;
  assignedToId: number;
  transferType: TransferType;
  participantRegNumber: string;
  participantDisplayName: string;
  createdBy: string;
  syncedDate: string;
}

export default TransferType;
