enum BusinessRole {
  Hauler = "Hauler",
  Producer = "Producer",
  Processor = "Processor",
  Collector = "Collector",
  OneTimePickup = "OneTimePickup",
  RPM = "RPM",
  Reuse = "Reuse",
  Landfill = "Landfill",
  EnergyFuel = "EnergyFuel",
  Recycling = "Recycling",
  Retreader = "Retreader",
}

export default BusinessRole;
