import React, { useState } from "react";
import { handleMouseDown } from "../../helpers/buttonHandlers";
import { VisibilityIcon, InvisibilityIcon } from "../Icons";

import TextInput, { TextInputProps } from "../TextInput";

import styles from "./PasswordInput.module.scss";

enum InputTypes {
  text = "text",
  password = "password",
}

const PasswordInput = (props: TextInputProps) => {
  const [isPasswordInvisible, setPasswordVisibility] = useState(true);
  const [currentInputType, setCurrentInputType] = useState(InputTypes.password);

  const handlerPasswordVisibility = () => {
    setPasswordVisibility(!isPasswordInvisible);
    const newInputType = isPasswordInvisible
      ? InputTypes.text
      : InputTypes.password;

    setCurrentInputType(newInputType);
  };

  const inputComponent = (
    <button
      className={styles.etPasswordInputVisibilityButton}
      onClick={handlerPasswordVisibility}
      type="button"
      onMouseDown={handleMouseDown}
      data-testid="button-icon-element"
    >
      {isPasswordInvisible ? <VisibilityIcon /> : <InvisibilityIcon />}
    </button>
  );

  return (
    <TextInput
      {...props}
      inputIconContent={inputComponent}
      type={currentInputType}
    />
  );
};

export default PasswordInput;
