import React, { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import ButtonContent from "./ButtonContent";

import { ButtonVariant, ButtonIconPosition } from "./types";

import styles from "./Button.module.scss";

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  id: string;
  to: string;
  variant?: ButtonVariant;
  isExternal?: boolean;
  iconPosition?: ButtonIconPosition;
  iconComponent?: React.ElementType;
}

const LinkButton = (props: Props) => {
  const {
    to,
    children,
    className,
    variant = "filled",
    isExternal = false,
    iconPosition,
    iconComponent,
    ...otherProps
  } = props;
  const Component = isExternal ? "a" : Link;

  return (
    <Component
      to={to}
      href={to}
      className={classnames(styles.button, styles[variant], className)}
      {...otherProps}
    >
      <ButtonContent iconPosition={iconPosition} iconComponent={iconComponent}>
        {children}
      </ButtonContent>
    </Component>
  );
};

export default LinkButton;
