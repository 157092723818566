import React, {
  useMemo,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";

import { queryCache } from "react-query";

import Header from "components/Header";
import Navigation from "components/Navigation";
import UserContext from "contexts/userContext";
import CompanyChecker from "routes/CompanyChecker";
import ChooseCompanyPage from "components/ChooseCompanyPage";
import { ExternalApp, NotFoundPage } from "fe-shared/src/components";
import { hasPermission } from "fe-shared/src/helpers/permissions";
import { Company, InviteStatuses } from "fe-shared/src/types/domain";

import useUpdateEffect from "fe-shared/src/hooks/useUpdateEffect";

import { ROUTES_MAP_CONFIG } from "helpers/routesMapConfig";

import config from "config";
import { companiesQueryKey, ROUTES, REFRESH_TOKEN_URL } from "consts";

import styles from "./ExternalAppsView.module.scss";

interface Props {
  companies: Company[];
  isInactiveEmployee: boolean;
  setInactiveEmployee: Dispatch<SetStateAction<boolean>>;
}

const getCurrentCompany = (
  companies: Company[],
  lastVisitedCompanyId?: number
) => {
  const company = companies.find(
    (c) =>
      c.companyId === lastVisitedCompanyId &&
      c.invitationStatus === InviteStatuses.Accepted
  );

  if (company) {
    return company;
  }
  if (companies.length === 1) {
    return companies[0];
  }
  return null;
};

const getRoutes = (company?: Company | null) =>
  company
    ? ROUTES_MAP_CONFIG.filter(
        ({ permission, allowedRoles }) =>
          hasPermission(company?.permissions, permission) &&
          allowedRoles.includes(company.businessRoleCode)
      )
    : [];

const ExternalAppsView = (props: Props) => {
  const { companies, setInactiveEmployee, isInactiveEmployee } = props;
  const { user, sessionId, setData } = useContext(UserContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const { lastVisitedCompanyId, lastVisitedCompanyName } = user || {};
  const [currentCompany, setCurrentCompany] = useState<Company | null>(() =>
    getCurrentCompany(companies, lastVisitedCompanyId)
  );
  const routes = useMemo(() => getRoutes(currentCompany), [currentCompany]);

  useEffect(() => {
    if (pathname === ROUTES.root && routes.length) {
      history.replace(
        currentCompany?.isBankingInfoOpen ? "/profile" : routes[0].path
      );
    }
  }, [history, pathname, routes, currentCompany]);

  useUpdateEffect(() => {
    if (currentCompany && user) {
      setData({
        ...user,
        lastVisitedCompanyId: currentCompany.companyId,
        lastVisitedCompanyName: currentCompany.displayName,
      });
    } else {
      queryCache.invalidateQueries(companiesQueryKey);
    }
  }, [currentCompany]);

  const setExceptionCompany = () => {
    setCurrentCompany(null);
    setInactiveEmployee(true);
  };

  const onChooseCompany = (company: Company) => {
    if (company.companyId !== currentCompany?.companyId) {
      if (company.isBankingInfoOpen) {
        history.replace("/profile");
      }

      const newRoutes = getRoutes(company);
      const isNewCompanyWithCurrentRoute = newRoutes.some((r) =>
        pathname.startsWith(r.path)
      );

      if (!isNewCompanyWithCurrentRoute) {
        history.replace(newRoutes[0].path);
      }
    }
    setCurrentCompany(company);
    setInactiveEmployee(false);
  };

  const changeCompanyByRoute = (company: Company) => {
    setCurrentCompany(company);
    setInactiveEmployee(false);
  };

  return currentCompany ? (
    <>
      <Header
        companies={companies}
        currentCompany={currentCompany}
        onChooseCompany={onChooseCompany}
      />
      <main className={styles.etExternalAppsView}>
        <Navigation
          routes={routes}
          businessRoleCode={currentCompany?.businessRoleCode}
        />
        <div className={styles.etExternalAppsViewContainer}>
          <Switch key={currentCompany?.companyId}>
            {routes.map(({ path, ...rest }) => (
              <Route key={path} path={path}>
                <ExternalApp
                  user={user}
                  sessionId={sessionId}
                  company={currentCompany}
                  env={config.SENTRY_ENV}
                  apiBaseUrl={config.API_HOST_URL}
                  refreshTokenUrl={REFRESH_TOKEN_URL}
                  setUserData={setData}
                  forbiddenCallback={setExceptionCompany}
                  {...rest}
                />
              </Route>
            ))}
            <Route path={ROUTES.company} exact>
              <CompanyChecker
                companies={companies}
                currentCompany={currentCompany}
                changeCompany={changeCompanyByRoute}
              />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
      </main>
    </>
  ) : (
    <ChooseCompanyPage
      companies={companies}
      onChooseCompany={onChooseCompany}
      isInactiveEmployee={isInactiveEmployee}
      lastVisitedCompanyId={lastVisitedCompanyId}
      lastVisitedCompanyName={lastVisitedCompanyName}
    />
  );
};

export default ExternalAppsView;
