import React from "react";

import { IconProps } from "../../types/common";

const QuestionIcon = ({
  width = "20",
  height = "20",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 20 20"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M0 10a10 10 0 1120 0 10 10 0 01-20 0zm2 0a8.01 8.01 0 0016 0 8.01 8.01 0 00-16 0zm9 4v2H9v-2h2zM6.18 6.83a4 4 0 014.43-2.79 4.08 4.08 0 013.35 3.47c.23 1.61-.66 2.4-1.53 3.14l-.46.42c-.12.11-.23.22-.33.34l-.02.02-.01.02a2.76 2.76 0 00-.33.48c-.17.3-.28.65-.28 1.07H9a3.72 3.72 0 01.2-1.28l.02-.04.02-.04.02-.04a3.33 3.33 0 01.27-.53l.01-.02v-.01l.01-.01c.34-.51.8-.86 1.22-1.2.62-.46 1.17-.89 1.23-1.68.08-.97-.62-1.9-1.57-2.1a2 2 0 00-2.3 1.28c-.14.38-.47.67-.88.67h-.2a.9.9 0 01-.87-1.17z"
    />
  </svg>
);

export default QuestionIcon;
