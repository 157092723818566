import BusinessRole from "./BusinessRole";
import { UserPermissionsDictionary } from "./Permissions";

export enum InviteStatuses {
  Invited = "Invited",
  Accepted = "Accepted",
  Expired = "Expired",
  Revoked = "Revoked",
}

export interface Company {
  companyId: number;
  regNumber: number;
  businessRoleCode: BusinessRole;
  permissions: UserPermissionsDictionary;
  invitationStatus: null | InviteStatuses;
  employeeWarehouseId: number | null;
  displayName: string;
  isBankingInfoOpen?: boolean;
}
