import React, { useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import {
  Button,
  Loader,
  ApiErrorBanner,
  TextInput,
  LinkButton,
  LogoLayout,
} from "fe-shared/src/components";

import useValidation from "fe-shared/src/hooks/useValidation";

import { ApiError } from "fe-shared/src/types/common";

import * as api from "./modules/api";

import { FORM_CONFIGURATION, errorMessages } from "./modules/config";

import styles from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const [isEmailValid, setValidEmail] = useState<boolean>(false);
  const [currentEmail, setCurrentEmail] = useState<string>("");

  const [generateTokenLink, mutationResult] = useMutation<
    unknown,
    AxiosError<ApiError>,
    api.ForgotPasswordParams
  >(api.forgotPassword, {
    onSuccess: () => {
      setValidEmail(true);
    },
  });

  const { isLoading, isError } = mutationResult;

  const { t } = useTranslation();
  const { validationConfig, initialContent } = FORM_CONFIGURATION;

  const { values, errors, updateValue, validateValue, isValid } = useValidation(
    validationConfig,
    initialContent,
    errorMessages
  );

  const succesContent = (
    <>
      <h1>{t("forgotPassword.linkSent")}</h1>
      <div className={styles.etForgotPasswordEndDescription}>
        {t("forgotPassword.alreadySentDescription")}
        &nbsp;
        <p>{currentEmail}</p>
      </div>
    </>
  );

  const tryReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValid) {
      generateTokenLink(values);
      setCurrentEmail(values.email);
    }
  };

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      {isEmailValid ? (
        succesContent
      ) : (
        <>
          <h1>{t("forgotPassword.title")}</h1>
          <p className={styles.etForgotPasswordDescription}>
            {t("forgotPassword.description")}
          </p>
          {isError && (
            <ApiErrorBanner
              id="forgot-error-banner"
              requestStatus={mutationResult}
            />
          )}
          <form
            onSubmit={tryReset}
            aria-label={t("login.form")}
            className={styles.etForgotPasswordControls}
          >
            <TextInput
              name="email"
              type="email"
              id="forgot-email-input"
              autoComplete="on"
              inputClassName={styles.etForgotPasswordInput}
              value={values.email}
              isError={!!errors.email}
              errorText={errors.email}
              onBlur={() => validateValue("email")}
              onChange={(e) => updateValue("email", e.target.value)}
            />
            <Button
              type="submit"
              id="start-application-button"
              disabled={!values.email || !!Object.keys(errors).length}
            >
              {t("forgotPassword.sendButton")}
            </Button>
          </form>
          <div className={styles.etForgotPasswordBottomLine}>
            <p>{t("forgotPassword.rememberPassword")}</p>
            <LinkButton
              to="/login"
              id="remember-password-link"
              variant="inline"
            >
              {t("forgotPassword.login")}
            </LinkButton>
          </div>
        </>
      )}
    </LogoLayout>
  );
};

export default ForgotPassword;
