import { forwardRef, useImperativeHandle, useState } from "react";
import { IFilterParams, IDoesFilterPassParams } from "@ag-grid-community/core";

import { DataGridSingleSelectFilter } from "../modules/types";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

interface Props extends IFilterParams {
  doesFilterPass?: (
    value: string | number,
    params: IDoesFilterPassParams
  ) => boolean;
}

export default forwardRef((props: Props, ref) => {
  const { filterChangedCallback, doesFilterPass } = props;
  const [selected, setSelected] = useState<string | number>("");

  useUpdateEffect(() => {
    const timeout = setTimeout(() => {
      filterChangedCallback();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [selected, filterChangedCallback]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive: () => !!selected,
      doesFilterPass: (params: IDoesFilterPassParams) => {
        if (doesFilterPass) {
          return doesFilterPass(selected, params.data);
        }
        return true;
      },
      getModel: () => ({ type: "singleSelectFilter", value: selected }),
      setModel: (model: DataGridSingleSelectFilter) => {
        setSelected(model?.value || "");
      },
    };
  });

  return null;
});
