import { useState, useEffect } from "react";

export interface ElementSize {
  width: number;
  height: number;
}

function useElementSize(ref: React.RefObject<HTMLElement>): ElementSize {
  const [size, setSize] = useState<ElementSize>(() => ({
    width: ref.current?.clientWidth || 0,
    height: ref.current?.clientHeight || 0,
  }));

  useEffect(() => {
    let resizeObserver: ResizeObserver;

    if (ref.current) {
      resizeObserver = new ResizeObserver((entries) => {
        const borderBoxSize = entries[0].borderBoxSize as
          | ResizeObserverEntry["borderBoxSize"][]
          | ResizeObserverEntry["borderBoxSize"];
        const borderBox = Array.isArray(borderBoxSize)
          ? borderBoxSize[0]
          : borderBoxSize;

        if (borderBox) {
          const { inlineSize: width, blockSize: height } = borderBox;

          setSize({ width, height });
        } else {
          setSize({
            width: ref.current?.clientWidth || 0,
            height: ref.current?.clientHeight || 0,
          });
        }
      });

      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [ref]);
  return size;
}

export default useElementSize;
