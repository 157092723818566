import React, { forwardRef, useImperativeHandle, useState } from "react";
import { IFloatingFilterParams } from "@ag-grid-community/core";

import TextInput from "../../TextInput";
import IconButton from "../../IconButton";
import { RemoveIcon } from "../../Icons";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { DataGridTextFilter } from "../modules/types";

import styles from "./DateRangeFilter.module.scss";

interface Props extends IFloatingFilterParams {
  placeholder: string;
}

export default forwardRef((props: Props, ref) => {
  const { column, placeholder = "Filter", parentFilterInstance } = props;
  const [text, setText] = useState("");

  useUpdateEffect(() => {
    parentFilterInstance((instance) => instance.setModel({ value: text }));
  }, [text, parentFilterInstance]);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged: (parentModel: DataGridTextFilter) => {
        setText(parentModel?.value || "");
      },
    };
  });

  const clearButtonComponent = (
    <IconButton
      id={`clear-${column.getColId()}`}
      aria-label="Clear"
      onClick={() => setText("")}
      className={styles.textInputClearButton}
    >
      <RemoveIcon fillColor={null} />
    </IconButton>
  );

  return (
    <TextInput
      value={text}
      style={{ height: 32, width: "100%" }}
      id={column.getColId()}
      placeholder={placeholder}
      onChange={(e, value) => setText(value)}
      inputIconContent={text && clearButtonComponent}
    />
  );
});
