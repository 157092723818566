import React from "react";

import { IconProps } from "../../types/common";

const ErrorIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#E64040",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M12 2a10 10 0 100 20 10 10 0 000-20zm0 11a1 1 0 01-1-1V8a1 1 0 011-1 1 1 0 011 1v4a1 1 0 01-1 1zm-1 2v2h2v-2h-2z"
    />
  </svg>
);

export default ErrorIcon;
