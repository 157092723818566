import React, { ReactNode } from "react";
import classnames from "classnames";

import styles from "./FormControlMessage.module.scss";

interface Props {
  id: string;
  errorText?: string;
  hintText?: string | ReactNode;
  isError?: boolean;
  className?: string;
}

const FormControlMessage = (props: Props) => {
  const { id, errorText, hintText, isError, className } = props;
  const showErrorMessage = !!errorText && isError;
  const showHintText = !showErrorMessage && hintText;

  return (
    <>
      {showErrorMessage && (
        <span
          role="alert"
          id={`${id}-error-msg`}
          className={classnames(
            styles.formControlMessage,
            styles.formControlMessageError,
            className
          )}
        >
          {errorText}
        </span>
      )}
      {showHintText && (
        <span
          id={`${id}-hint-msg`}
          className={classnames(
            styles.formControlMessage,
            styles.formControlMessageHint,
            className
          )}
        >
          {hintText}
        </span>
      )}
    </>
  );
};

export default FormControlMessage;
