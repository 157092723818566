import React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";

import { CheckIcon } from "../Icons";

import "react-toastify/dist/ReactToastify.css";
import styles from "./Notification.module.scss";

export interface NotificationAction {
  label: string;
  callback: () => void;
}

interface Props {
  message: string;
  action?: NotificationAction;
  iconComponent?: React.ElementType;
}

const NotificationComponent = ({
  message,
  action,
  iconComponent: Icon = CheckIcon,
}: Props) => {
  return (
    <div className={styles.etNotificationWrapper}>
      <Icon className={styles.etNotificationIcon} />
      <span className={styles.etNotificationInfo}>{message}</span>
      {action && (
        <button
          type="button"
          onClick={action.callback}
          className={styles.etNotificationButton}
        >
          {action.label}
        </button>
      )}
    </div>
  );
};

export const showNotification = (
  message: string,
  action?: NotificationAction,
  iconComponent?: React.ElementType
) => {
  return toast(
    <NotificationComponent
      action={action}
      message={message}
      iconComponent={iconComponent}
    />,
    {
      className: styles.toastWrapper,
    }
  );
};

export const NotificationsContainer = () => {
  return (
    <ToastContainer
      draggable
      pauseOnHover
      hideProgressBar
      pauseOnFocusLoss
      autoClose={5000}
      transition={Slide}
      closeButton={false}
      newestOnTop={false}
      closeOnClick={false}
      draggablePercent={50}
      position="bottom-center"
      className={styles.etNotification}
    />
  );
};
