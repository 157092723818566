import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import classnames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import {
  Loader,
  FormControlLabel,
  FormControlMessage,
  FormControlDropdown,
} from "..";

import useElementSize from "../../hooks/useElementSize";
import useMultiDropdown from "../../hooks/useMultiDropdown";

import { Option, OptionValue } from "./types";

import styles from "./SelectInput.module.scss";
import GroupedOption from "./components/GroupedOption";

export interface Props {
  id: string;
  options?: Option[];
  required?: boolean;
  width?: number;
  title?: React.ReactNode;
  tooltipText?: string;
  value: OptionValue[];
  wrapperClassName?: string;
  listClassName?: string;
  labelClassName?: string;
  messageClassName?: string;
  className?: string;
  placeholder?: ReactNode;
  isLoading?: boolean;
  onChange: (event: React.SyntheticEvent, values: OptionValue[]) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorText?: string;
  isError?: boolean;
  hintText?: string;
}

const defaultProps = {
  options: [],
};

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

function getFlatOptionValues(options: Option[], value: OptionValue[]) {
  return options.reduce(
    (acc: OptionValue[], cur) =>
      value.includes(cur.value) ? [...acc, cur.value] : acc,
    [] as OptionValue[]
  );
}

const MultiSelectInputWithGrouping = (props: Props) => {
  const {
    id,
    title,
    tooltipText,
    options = defaultProps.options,
    wrapperClassName,
    listClassName,
    className,
    labelClassName,
    messageClassName,
    width,
    onChange,
    onBlur,
    value,
    placeholder,
    isLoading,
    disabled = false,
    required,
    isError,
    errorText,
    hintText,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLUListElement>(null);
  const tippyRef = useRef<any>(null);
  const { width: minWidth } = useElementSize(ref);
  const {
    selectedIdx,
    isDropdownOpened,
    onKeyDown,
    changeDropdownState,
    onValueChange,
  } = useMultiDropdown(ref, optionsWrapperRef, options, onChange, value);

  const updateTippy = useCallback(() => {
    setTimeout(() => {
      tippyRef?.current?._tippy?.popperInstance?.update();
    });
  }, [tippyRef]);

  useEffect(() => {
    window.addEventListener("resize", updateTippy);

    return () => {
      window.removeEventListener("resize", updateTippy);
    };
  }, [updateTippy]);

  const optionsMapSelected = useMemo(
    () =>
      new Map(
        options
          .map((option: Option): [OptionValue, OptionValue[]] => {
            if (option.options !== undefined) {
              return [option.value, getFlatOptionValues(option.options, value)];
            }

            return [
              option.value,
              value.includes(option.value) ? [option.value] : [],
            ];
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([k, v]) => v.length > 0)
      ),
    [options, value]
  );

  const getGroupedOption = (option: Option) => (
    <GroupedOption
      id={id}
      option={option}
      onChange={(e, newValues) => {
        if (option.options === undefined) {
          onValueChange(e, ...newValues);
          return;
        }

        optionsMapSelected.set(option.value, newValues);
        onChange(
          e,
          Array.from(optionsMapSelected.values()).flat() as OptionValue[]
        );
      }}
      selected={value}
    />
  );

  const tooltipContent = (
    <FormControlDropdown
      wrapperRef={optionsWrapperRef}
      selectedIdx={selectedIdx}
      options={options}
      onChange={() => null}
      getOption={getGroupedOption}
      minWidth={minWidth}
      wrapperClassName={listClassName}
    />
  );

  return (
    <div
      style={{ width }}
      className={classnames(styles.selectWrapper, wrapperClassName)}
    >
      <FormControlLabel
        id={id}
        title={title}
        tooltipText={tooltipText}
        required={required}
        className={labelClassName}
      />
      <Tippy
        interactive
        arrow={false}
        ref={tippyRef}
        maxWidth="auto"
        offset={[0, 2]}
        placement="bottom-start"
        content={tooltipContent}
        visible={isDropdownOpened}
        popperOptions={popperOptions}
        appendTo={() => document.body}
        className={styles.selectTooltip}
      >
        <div
          className={classnames(styles.select, className, {
            [styles.selectEmpty]: !value.length,
            [styles.selectError]: isError,
            [styles.disabled]: isLoading || disabled,
            [styles.disabledClick]: disabled,
          })}
          ref={ref}
          id={id}
          role="button"
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          tabIndex={isLoading ? null! : 0}
          onClick={() => changeDropdownState()}
        >
          {isLoading && (
            <span className={styles.selectLoader}>
              <Loader fontSize={24} />
            </span>
          )}
          <span className={styles.selectPlaceholder}>{placeholder}</span>
        </div>
      </Tippy>
      <FormControlMessage
        id={id}
        errorText={errorText}
        hintText={hintText}
        isError={isError}
        className={messageClassName}
      />
    </div>
  );
};

export default MultiSelectInputWithGrouping;
