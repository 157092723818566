export enum TransactionType {
  Pickup = "Pickup",
  Shipment = "Shipment",
  Delivery = "Delivery",
  Receipt = "Receipt",
  Disposition = "Disposition",
  Adjustment = "Adjustment",
  Retreading = "Retreading",
}

export interface Transaction {
  transactionId: number;
  transactionNumber: string;
  transactionType: TransactionType;
  syncedDate?: string;
  submittedDate?: string;
  completedDate?: string;
}

export default TransactionType;
