import React from "react";

import { IconProps } from "../../types/common";

const CloseIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M18.3 5.71a1 1 0 00-1.41 0L12 10.59 7.11 5.7A1 1 0 105.7 7.11L10.59 12 5.7 16.89a1 1 0 101.41 1.41L12 13.41l4.89 4.89a1 1 0 101.41-1.41L13.41 12l4.89-4.89a1 1 0 000-1.4z"
    />
  </svg>
);

export default CloseIcon;
