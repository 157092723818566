import { AxiosError } from "axios";

import { ErrorCode } from "fe-shared/src/types/common";
import { PasswordApiError } from "components/PasswordErrorBanner";
import { ErrorReasons } from "components/BrokenLink";

import { ROUTES } from "consts";
import { TokenErrorCodes } from "types";

export const getTokenErrorInfo = (error: AxiosError<PasswordApiError>) => {
  const { additionalInfo, errorCode } = error?.response?.data || {};

  return {
    tokenErrorCode:
      errorCode === ErrorCode.InactiveEmployee
        ? TokenErrorCodes.InactiveEmployee
        : additionalInfo?.[0]?.code,
    email: additionalInfo?.[0]?.details?.email,
  };
};

export const getResetPasswordRedirectRoute = (
  error: AxiosError<PasswordApiError>
) => {
  const { tokenErrorCode } = getTokenErrorInfo(error);

  switch (tokenErrorCode) {
    case TokenErrorCodes.TokenExpiration:
    case TokenErrorCodes.InactiveEmployee:
      return `${ROUTES.brokenLink}/${ErrorReasons.Expired}`;
    case TokenErrorCodes.TokenReusage:
      return `${ROUTES.brokenLink}/${ErrorReasons.Used}`;
    default:
      return `${ROUTES.brokenLink}/${ErrorReasons.Invalid}`;
  }
};

export const getRedirectRoute = (tokenErrorCode?: string) => {
  switch (tokenErrorCode) {
    case TokenErrorCodes.TokenRevoked:
    case TokenErrorCodes.TokenExpiration:
    case TokenErrorCodes.InactiveEmployee:
      return ROUTES.inactiveInvite;
    default:
      return `${ROUTES.brokenLink}/${ErrorReasons.Invalid}`;
  }
};

export const getNewUserRedirectRoute = (
  error: AxiosError<PasswordApiError>,
  token: string,
  isWebInivitation: boolean
) => {
  const { tokenErrorCode } = getTokenErrorInfo(error);

  if (tokenErrorCode === TokenErrorCodes.PasswordCreated) {
    return `${
      isWebInivitation ? ROUTES.acceptWebInvite : ROUTES.acceptMobileInvite
    }/${token}`;
  }
  return getRedirectRoute(tokenErrorCode);
};

export const getExistingUserRedirectRoute = (
  error: AxiosError<PasswordApiError>
) => {
  const { tokenErrorCode } = getTokenErrorInfo(error);

  return getRedirectRoute(tokenErrorCode);
};
