import {
  UserPermissionsDictionary,
  UserPermission,
  PermissionLevel,
} from "../types/domain/Permissions";

const PERMISSION_LEVEL_WEIGHT: { [key in PermissionLevel]: number } = {
  [PermissionLevel.Deny]: 0,
  [PermissionLevel.Read]: 1,
  [PermissionLevel.Full]: 2,
};

export const hasPermission = (
  permissionsDictionary: UserPermissionsDictionary = {},
  permission?: UserPermission
) => {
  if (!permission) {
    return true;
  }

  const permissionLevel = permissionsDictionary[permission.name];

  if (!permissionLevel) {
    return false;
  }

  return (
    PERMISSION_LEVEL_WEIGHT[permissionLevel] >=
    PERMISSION_LEVEL_WEIGHT[permission.level]
  );
};

export const hasAnyPermission = (
  permissionsDictionary?: UserPermissionsDictionary,
  permissions: UserPermission[] = []
) => permissions.some((p) => hasPermission(permissionsDictionary, p));

export const hasAllPermissions = (
  permissionsDictionary?: UserPermissionsDictionary,
  permissions: UserPermission[] = []
) => permissions.every((p) => hasPermission(permissionsDictionary, p));

export const getFullPermissionCode = (
    availablePermissions : string[], 
    permissionNamePart : string) => 
        availablePermissions.find(
            permissionName => permissionName.includes(permissionNamePart)) || ""
