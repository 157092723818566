import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./LoginRequirements.module.scss";

const LoginRequirements = () => {
  const { t } = useTranslation();
  const requirements = t<string[]>("passwordActions.requirements", {
    returnObjects: true,
  });

  return (
    <div className={styles.etLoginRequirements}>
      <span>{t("passwordActions.passwordRequirements")}</span>
      <ul>
        {requirements.map((requirement) => (
          <li key={requirement}>{requirement}</li>
        ))}
      </ul>
    </div>
  );
};

export default LoginRequirements;
