import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { IFloatingFilterParams } from "@ag-grid-community/core";

import { DataGridSingleSelectFilter } from "../modules/types";
import { SelectInput } from "../../index";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import styles from "./MultiSelectFilter.module.scss";

interface Option {
  label: string;
  value: string;
}

interface Props extends IFloatingFilterParams {
  id: string;
  placeholder: string;
  options: Option[];
  disabled?: boolean;
}

export default forwardRef((props: Props, ref) => {
  const {
    id,
    placeholder = "Any",
    options,
    parentFilterInstance,
    disabled = false,
  } = props;
  const [selected, setSelected] = useState<string | number>("");

  useUpdateEffect(() => {
    parentFilterInstance((instance) => instance.setModel({ value: selected }));
  }, [selected, parentFilterInstance]);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged: (parentModel: DataGridSingleSelectFilter) => {
        setSelected(parentModel?.value || "");
      },
    };
  });

  const onChange = (_: any, values: unknown) => {
    setSelected(values as (string | number));
  };

  const selectedPlaceholder = useMemo(() => {
    return selected
      ? options?.find((item) => item.value === selected)?.label
      : placeholder;
  }, [options, selected, placeholder]);

  return (
    <SelectInput
      id={id}
      value={selected}
      options={options}
      onChange={onChange}
      disabled={disabled}
      placeholder={selectedPlaceholder}
      wrapperClassName={styles.selectFilterInput}
    />
  );
});
