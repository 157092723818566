import { ReactQueryConfig } from "react-query";

const QUERY_CONFIG: ReactQueryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  },
};

export default QUERY_CONFIG;
