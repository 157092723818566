import { useMemo, useState, useEffect, SyntheticEvent, RefObject } from "react";

import useScrollToIndex from "./useScrollToIndex";
import useOnClickOutside from "./useOnClickOutside";

interface Option {
  label: string;
  value: string | number;
}

interface IndexedOption extends Option {
  index: number;
}

const defaultOptions: Option[] = [];
const defaultOnChange = () => {};

const useDropdown = (
  ref: RefObject<HTMLElement>,
  optionsWrapperRef: RefObject<HTMLElement>,
  options: Option[] = defaultOptions,
  onChange: (
    event: SyntheticEvent,
    value: number | string,
    label: string
  ) => void = defaultOnChange,
  value?: number | string
) => {
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const hasOptions = !!options.length;

  const optionsByValue = useMemo(
    () =>
      options.reduce((result, option, index) => {
        result[option.value] = {
          ...option,
          index,
        };
        return result;
      }, {} as { [key: string]: IndexedOption }),
    [options]
  );
  const { index: valueIdx = 0, label } = optionsByValue[value!] || {};

  useOnClickOutside([ref, optionsWrapperRef], () => setIsDropdownOpened(false));

  const changeDropdownState = (isOpened: boolean = !isDropdownOpened) => {
    setIsDropdownOpened(isOpened);
    setSelectedIdx(isOpened ? valueIdx : -1);
  };

  const onValueChange = (e: React.SyntheticEvent, idx: number) => {
    if (options[idx]) {
      onChange(e, options[idx].value, options[idx].label);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const getMinIdx = (idx: number) => Math.max(idx - 1, 0);
    const getMaxIdx = (idx: number) => Math.min(idx + 1, options.length - 1);
    let otherKeyPressed = false;
    let newIdx = selectedIdx;

    switch (e.key) {
      case "Tab":
        changeDropdownState(false);
        break;
      case "ArrowUp":
        if (!hasOptions) {
          break;
        }
        if (isDropdownOpened) {
          newIdx = getMinIdx(selectedIdx);
        } else {
          onValueChange(e, getMinIdx(valueIdx));
        }
        break;
      case "ArrowDown":
        if (!hasOptions) {
          break;
        }
        if (isDropdownOpened) {
          newIdx = getMaxIdx(selectedIdx);
        } else {
          onValueChange(e, getMaxIdx(valueIdx));
        }
        break;
      case "Enter":
        if (!isDropdownOpened) {
          changeDropdownState(true);
          break;
        }
        if (selectedIdx !== -1) {
          onValueChange(e, selectedIdx);
          changeDropdownState(false);
        }
        break;
      case " ":
        if (!isDropdownOpened) {
          changeDropdownState(true);
        }
        break;
      case "Escape":
        if (isDropdownOpened) {
          changeDropdownState(false);
        }
        break;
      default:
        otherKeyPressed = true;
        break;
    }
    if (newIdx !== selectedIdx) {
      setSelectedIdx(newIdx);
    }
    if (!otherKeyPressed && e.key !== "Tab" && e.key !== " ") {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useScrollToIndex(isDropdownOpened, selectedIdx, optionsWrapperRef, options);

  useEffect(() => {
    setSelectedIdx(options.length ? 0 : -1);
  }, [options]);

  return {
    label,
    selectedIdx,
    isDropdownOpened,
    onKeyDown,
    changeDropdownState,
  };
};

export default useDropdown;
