import { Dispatch, ElementType, SetStateAction } from "react";
import { MutationResult, QueryResult } from "react-query";
import { AxiosError } from "axios";
import {
  MaterialInfo,
  Transaction,
  TransactionType,
  Transfer,
  TransferType,
} from "../domain";
import { ApiError } from "./errors";

export enum TransactionCategory {
  Transactions = "Transaction",
  Transfers = "Transfer",
  InventoryAdjustment = "Adjustment",
}

export enum RecordsTemplate {
  Default = "default",
  LinkedBatch = "batches",
}

export interface RecordsListItem {
  id: number;
  text: string;
  createdBy: string;
  createdDate: string;
}

export interface TransactionRecordsListItem {
  completedDate: string;
  transactionNumber: string;
  materials: MaterialInfo[];
  transactionCategory: TransactionCategory;
  transactionTypeCode: TransactionType | TransferType;
}

export interface ExcelColumnConfig<T> {
  getOriginalValue: (item: T) => unknown;
  mappedKey: string;
  width: number;
}

export interface RecordsListProps {
  translationPrefix: string;
  iconComponent: ElementType;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  queryResult: QueryResult<RecordsListItem[], AxiosError<ApiError>>;
  logExport: () => void;
  canExport?: boolean;
  canAdd?: boolean;
  addRecord?: (text: string) => void;
  addRecordStatus?: MutationResult<RecordsListItem, AxiosError<ApiError>>;
  columnConfig?: ExcelColumnConfig<RecordsListItem>[];
  getDialogTitle?: (title: string) => React.ReactNode;
  fileNamePrefix: string;
  fileNameSuffix: string;
  entityName: string;
}

export interface TransactionRecordsListProps
  extends Omit<
    RecordsListProps,
    | "search"
    | "setSearch"
    | "logExport"
    | "fileNamePrefix"
    | "fileNameSuffix"
    | "queryResult"
  > {
  queryResult: QueryResult<TransactionRecordsListItem[], AxiosError<ApiError>>;

  transactionOrTransfer: Transaction | Transfer;
}
