import axios from "axios";
import { useEffect, useState } from "react";

const useAuthorizationHeader = (token?: string | null) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.Authorization;
    }
    setIsLoggedIn(!!token);
  }, [token]);

  return isLoggedIn;
};

export default useAuthorizationHeader;
