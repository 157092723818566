import React from "react";
import { useTranslation } from "react-i18next";

import { LinkButton } from "..";

import styles from "./NotFoundPage.module.scss";

interface Props {
  title?: string;
  descriptions?: string[];
  returnLink?: string;
  returnLinkDescription?: string;
  id?: string;
}

const NotFoundPage = (props: Props) => {
  const {
    title = "page404.title",
    descriptions = ["page404.firstDescription"],
    returnLink = "/",
    returnLinkDescription = "page404.buttonName",
    id = "404-page",
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.etNotFound}>
      <h1>{t(title)}</h1>
      {descriptions.map((description) => {
        return (
          <p key={description} className={styles.etNotFoundContent}>
            {t(description)}
          </p>
        );
      })}
      <LinkButton to={returnLink} className={styles.etNotFoundButton} id={id}>
        {t(returnLinkDescription)}
      </LinkButton>
    </div>
  );
};

export default NotFoundPage;
