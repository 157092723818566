import axios from "axios";

import { TokenType } from "types";

export interface TokenInfoResponse {
  email: string;
}

export interface AcceptInvitationResponse {
  email: string;
  companyId: number;
  displayName: string;
}

export const getTokenInformation = (
  queryKey: string,
  token: string,
  type: TokenType
): Promise<string> =>
  axios
    .post<TokenInfoResponse>(
      "account/validateActionToken",
      {
        token,
        type,
      },
      { hideToast: true }
    )
    .then((res) => res.data.email);

export const acceptInvitationAnonymously = (
  queryKey: string,
  token: string,
  type: TokenType
): Promise<AcceptInvitationResponse> =>
  axios
    .post<AcceptInvitationResponse>("/employees/acceptInvitationAnonymously", {
      token,
      type,
    })
    .then((res) => res.data);
