import React from "react";

import { IconProps } from "../../types/common";

const InvoiceIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#63696F",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor!}
      className={fillClassName}
      clipRule="evenodd"
      d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM11.41 16.67V16.09C13.15 15.76 14.52 14.76 14.54 12.93C14.54 10.41 12.38 9.54 10.36 9.02C8.34 8.5 7.69 7.95 7.69 7.11C7.69 6.15 8.59 5.47 10.09 5.47C11.35 5.47 11.98 5.96 12.21 6.72C12.31 7.08 12.61 7.34 12.98 7.34H13.24C13.83 7.34 14.25 6.76 14.05 6.21C13.67 5.15 12.8 4.29 11.42 3.95V3.33C11.42 2.6 10.82 2 10.09 2H10.08C9.35 2 8.75 2.6 8.75 3.33V3.93C7.03 4.3 5.64 5.42 5.64 7.14C5.64 9.19 7.34 10.21 9.82 10.81C12.05 11.34 12.49 12.12 12.49 12.95C12.49 13.56 12.05 14.54 10.09 14.54C8.63 14.54 7.87 14.02 7.58 13.27C7.44 12.92 7.14 12.67 6.77 12.67H6.53C5.93 12.67 5.5 13.28 5.73 13.83C6.23 15.06 7.41 15.79 8.74 16.07V16.67C8.74 17.4 9.34 18 10.07 18H10.08C10.81 18 11.41 17.4 11.41 16.67Z"
    />
  </svg>
);

export default InvoiceIcon;
