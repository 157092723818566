import React from "react";

import { IconProps } from "../../types/common";

const FolderOpenIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17Z"
    />
  </svg>
);

export default FolderOpenIcon;
