import axios from "axios";

export interface SetPasswordParams {
  token: string;
  email: string;
  password: string;
  confirmedPassword: string;
  isFirstLogin: boolean;
}

export interface SetPasswordResponse {
  token: string;
}

export const setPassword = ({
  confirmedPassword,
  password,
  email,
  token,
  isFirstLogin,
}: SetPasswordParams): Promise<SetPasswordResponse> =>
  axios
    .post(
      "account/resetPassword",
      {
        email,
        token,
        password,
        confirmedPassword,
        rememberMe: true,
        isFirstLogin,
      },
      { hideToast: true }
    )
    .then((res) => res.data);
