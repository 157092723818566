import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { setConsole, ReactQueryConfigProvider } from "react-query";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import "intersection-observer";

import App from "components/App";
import {
  NotificationsContainer,
  ErrorBoundary,
} from "fe-shared/src/components";

import queryConfig from "fe-shared/src/helpers/queryConfig";
import { beforeSend } from "fe-shared/src/helpers/sentry";
import "./i18n";
import "fe-shared/src/i18n";
import * as serviceWorker from "./serviceWorker";
import config from "./config";

import "fe-shared/src/styles/core.scss";

if (!("ResizeObserver" in window)) {
  // @ts-ignore
  window.ResizeObserver = Polyfill;
}

const history = createBrowserHistory();

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENV,
  release: "36.0", // TODO: move to config
  integrations: [
    // @ts-ignore
    new Integrations.BrowserTracing({
      // @ts-ignore
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
  ],
  tracesSampleRate: 1,
  debug: process.env.NODE_ENV !== "production",
  beforeSend,
});

axios.defaults.baseURL = config.API_HOST_URL;

if (process.env.NODE_ENV === "production") {
  setConsole({
    error: () => {},
    warn: () => {},
    log: () => {},
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <ReactQueryConfigProvider config={queryConfig}>
        <Router history={history}>
          <App />
        </Router>
        <NotificationsContainer />
      </ReactQueryConfigProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
