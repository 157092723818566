import React, { useContext, useState } from "react";
import { useQuery } from "react-query";

import UserContext from "contexts/userContext";
import { Loader, NotFoundPage } from "fe-shared/src/components";

import ExternalAppsView from "../ExternalAppsView";
import NoCompaniesPage from "../../../components/NoCompaniesPage";
import * as api from "../modules/api";
import { companiesQueryKey } from "../../../consts";

const ExternalAppsViewContainer = () => {
  const { user, setData } = useContext(UserContext);
  const [isInactiveEmployee, setInactiveEmployee] = useState<boolean>(false);

  const userDataQuery = useQuery("userData", api.getUserData, {
    onSuccess: (userData) => {
      setData({
        ...user,
        data: userData,
      });
    },
    cacheTime: 1000,
  });

  const companiesQuery = useQuery(companiesQueryKey, api.getUserCompanies, {
    cacheTime: 1000,
  });

  if (userDataQuery.isLoading || companiesQuery.isLoading) {
    return <Loader isFullScreen />;
  }

  if (userDataQuery.isError) {
    return <NotFoundPage />; // TODO: show proper error page
  }

  if (
    (companiesQuery.data?.length === 0 || companiesQuery.isError) &&
    !isInactiveEmployee
  ) {
    return <NoCompaniesPage />;
  }

  return (
    <ExternalAppsView
      companies={companiesQuery.data!}
      setInactiveEmployee={setInactiveEmployee}
      isInactiveEmployee={isInactiveEmployee}
    />
  );
};

export default ExternalAppsViewContainer;
