import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { LogoLayout } from "fe-shared/src/components";
import { ROUTES } from "consts";

import styles from "./InactiveInviteView.module.scss";

const InactiveInviteView = () => {
  const { t } = useTranslation();

  return (
    <LogoLayout>
      <h2 className={styles.header}>{t("inactiveInvite.title")}</h2>
      <p className={styles.description}>{t("inactiveInvite.description")}</p>
      <p className={styles.link}>
        <Trans i18nKey="inactiveInvite.link">
          <Link to={ROUTES.login}>Login</Link>
        </Trans>
      </p>
    </LogoLayout>
  );
};

export default InactiveInviteView;
