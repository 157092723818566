import { Joi, emailJoiSchema } from "fe-shared/src/helpers/validation";
import { EMAIL_ERROR_MESSAGES } from "fe-shared/src/helpers/errorMessages";

import { ErrorMessages, FormConfiguration } from "fe-shared/src/types/common";

import { LoginParams } from "hooks/useLogin";

export const errorMessages: ErrorMessages = {
  ...EMAIL_ERROR_MESSAGES,
  password: {
    "string.empty": "validation.passwordRequired",
  },
};

const initialContent = {
  email: "",
  password: "",
};

const validationConfig = Joi.object({
  email: emailJoiSchema,
  password: Joi.string().required(),
});

export const FORM_CONFIGURATION: FormConfiguration<LoginParams> = {
  initialContent,
  validationConfig,
};
