import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { GridApi, IFloatingFilterParams } from "@ag-grid-community/core";

import IconButton from "../../IconButton";
import { ClearFiltersIcon } from "../../Icons";

import { DataGridFilter } from "../modules/types";

import styles from "./ClearFilters.module.scss";

interface Props extends IFloatingFilterParams {
  id: string;
}

const isAnyFilterActive = (api: GridApi) =>
  Object.values(api.getFilterModel()).some((filter: DataGridFilter) => {
    const { value } = filter;

    return Array.isArray(value) ? value.some(Boolean) : !!value;
  });

export default forwardRef((props: Props, ref) => {
  const { id, api } = props;
  const [areFiltersApplied, setFiltersApplied] = useState(() =>
    isAnyFilterActive(api)
  );

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    const listener = () => {
      setFiltersApplied(isAnyFilterActive(api));
    };

    api.addEventListener("filterChanged", listener);

    return () => {
      api.removeEventListener("filterChanged", listener);
    };
  }, [api]);

  const resetFilters = () => {
    api.setFilterModel({});
  };

  return areFiltersApplied ? (
    <IconButton
      id={id}
      hasTooltip
      onClick={resetFilters}
      aria-label="Clear Filters"
      className={styles.clearFiltersButton}
    >
      <ClearFiltersIcon fillColor={null} />
    </IconButton>
  ) : (
    <div />
  );
});
