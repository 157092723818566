import React from "react";
import classnames from "classnames";

import { ButtonIconPosition } from "./types";

import styles from "./Button.module.scss";

interface Props {
  children: React.ReactNode;
  iconPosition?: ButtonIconPosition;
  iconComponent?: React.ElementType;
}

const ButtonContent = (props: Props) => {
  const {
    children,
    iconPosition = "left",
    iconComponent: IconComponent,
  } = props;

  return IconComponent ? (
    <span className={styles.buttonContent}>
      <IconComponent
        width={24}
        height={24}
        fillColor={null}
        className={classnames(
          styles.buttonIcon,
          styles[`buttonIcon${iconPosition}`]
        )}
      />
      {children}
    </span>
  ) : (
    <>{children}</>
  );
};

export default ButtonContent;
