import React from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { LinkButton } from "../index";
import { QuestionIcon } from "../Icons";

import styles from "./HelpPortal.module.scss";

interface HelpPortalParam {
  isCollapsed: boolean;
  link: string;
}

const HelpPortal = ({ isCollapsed, link }: HelpPortalParam) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames(styles.helpPortal, {
        [styles.helpPortalCollapsed]: isCollapsed,
      })}
    >
      <LinkButton
        id="help-portal-btn"
        className={classnames(styles.helpPortalBtn, {
          [styles.helpPortalBtnCollapsed]: isCollapsed,
        })}
        variant="inline"
        to={link}
        target="_blank"
        isExternal
      >
        <QuestionIcon />
        {!isCollapsed && <span>{t("help")}</span>}
      </LinkButton>
    </div>
  );
};

export default HelpPortal;
