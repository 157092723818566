import { forwardRef, useImperativeHandle, useState } from "react";
import { IFilterParams, IDoesFilterPassParams } from "@ag-grid-community/core";

import { DataGridSelectFilter } from "../modules/types";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

interface Props extends IFilterParams {
  doesFilterPass?: (value: string[], params: IDoesFilterPassParams) => boolean;
}

export default forwardRef((props: Props, ref) => {
  const { filterChangedCallback, doesFilterPass } = props;
  const [selected, setSelected] = useState<string[]>([]);

  useUpdateEffect(() => {
    const timeout = setTimeout(() => {
      filterChangedCallback();
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [selected, filterChangedCallback]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive: () => !!selected.length,
      doesFilterPass: (params: IDoesFilterPassParams) => {
        if (doesFilterPass) {
          return doesFilterPass(selected, params.data);
        }
        return true;
      },
      getModel: () => ({ type: "selectFilter", value: selected }),
      setModel: (model: DataGridSelectFilter) => {
        setSelected(model?.value || []);
      },
    };
  });

  return null;
});
