import React, { forwardRef, useImperativeHandle, useState } from "react";
import { IFloatingFilterParams } from "@ag-grid-community/core";

import DatePicker from "../../DatePicker";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { getDateObject, getDateString } from "../../../helpers/dates";
import { DataGridDateRangeFilter } from "../modules/types";

import styles from "./DateRangeFilter.module.scss";

interface Props extends IFloatingFilterParams {
  placeholder: string;
  isNoOffset?: boolean;
  isFutureEnabled?: boolean;
}

export default forwardRef((props: Props, ref) => {
  const { column, isNoOffset, isFutureEnabled = false } = props;
  const { placeholder = "Select dates", parentFilterInstance } = props;

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  useUpdateEffect(() => {
    parentFilterInstance((instance) => {
      instance.setModel({ value: [startDate, endDate] });
    });
  }, [startDate, endDate, parentFilterInstance]);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged: (parentModel: DataGridDateRangeFilter) => {
        const { value = [null, null] } = parentModel;
        const [modelStartDate, modelEndDate] = value;

        setStartDate(modelStartDate);
        setEndDate(modelEndDate);
      },
    };
  });

  const maxDate = isFutureEnabled ? undefined : new Date();

  return (
    <div className={styles.dateRangeWrapper}>
      <DatePicker
        id={column.getColId()}
        isNoOffset={isNoOffset}
        className="ag-custom-component-popup"
        startDate={getDateObject(startDate)}
        endDate={getDateObject(endDate)}
        setStartDate={(dateObj) => setStartDate(getDateString(dateObj))}
        setEndDate={(dateObj) => setEndDate(getDateString(dateObj))}
        maxDate={maxDate}
        placeholder={placeholder}
        isRangeSelect
      />
    </div>
  );
});
