export enum ErrorCode {
  BadRequest = 400,
  NotFound = 404,
  ServerError = 500,
  ApplicationAlreadySubmitted = 600,
  ApplicationValidationFailed = 601,
  UploadLimitExceeded = 602,
  ApplicationIncorrectStatus = 603,
  ApplicationUnrelatedDocument = 604,
  ApplicationEmpty = 605,
  JsonTooLong = 606,
  InactiveEmployee = 803,
}
export interface ApiError {
  errorCode: ErrorCode;
  message: string;
  description: string;
}
