import axios from "axios";

import {
  User,
  Company,
  UserPermissionsDictionary,
} from "fe-shared/src/types/domain";

interface CompanyPermissionsResponse {
  permissions: UserPermissionsDictionary;
}

export const getUserData = () =>
  axios.get<User>("/users/getInfo").then((res) => res.data);

export const getUserCompanies = async () => {
  const { data: companies } = await axios.get<Company[]>(
    "/companies/userCompanies"
  );
  const companyPermissions = await Promise.all(
    companies.map(({ companyId }) =>
      axios
        .get<CompanyPermissionsResponse>("/employees/getEmployeePermissions", {
          params: { companyId },
        })
        .then((res) => res.data.permissions)
    )
  );

  return companies.map((company, index) => ({
    ...company,
    permissions: companyPermissions[index],
  }));
};

export const acceptInvitation = (companyId: number) =>
  axios
    .post<Company[]>(
      "/employees/acceptInvitation",
      { invitationType: "web-app" },
      { params: { companyId }, errorMessage: "errors.somethingWrong" }
    )
    .then((res) => res.data);
