import React from "react";

import { IconProps } from "../../types/common";

const SuccessIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#27A83B",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M2 12a10 10 0 1120 0 10 10 0 01-20 0zm3.7.7l3.59 3.59a1 1 0 001.41 0l7.59-7.59a1 1 0 10-1.41-1.41L10 14.17l-2.89-2.88A1 1 0 105.7 12.7z"
    />
  </svg>
);

export default SuccessIcon;
