import React from "react";
import { useTranslation } from "react-i18next";
import { SyncIcon, SyncProblemIcon } from "../Icons";
import { GPSyncStatusCode } from "../../types/domain";
import styles from "./GPSyncStatus.module.scss";

interface Props {
  synchronizationStatusCode: GPSyncStatusCode;
}

const statusIcon: Record<GPSyncStatusCode, React.ReactNode> = {
  [GPSyncStatusCode.Synchronized]: <SyncIcon />,
  [GPSyncStatusCode.Failed]: <SyncProblemIcon />,
  [GPSyncStatusCode.Pending]: <SyncIcon fillColor="#B5BDC2" />,
  [GPSyncStatusCode.InProgress]: <SyncIcon fillColor="#EEC200" />,
};

const GPSyncStatus = ({
  synchronizationStatusCode = GPSyncStatusCode.Pending,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.syncStatusWrapper}>
      {statusIcon[synchronizationStatusCode]}
      <span className={styles[synchronizationStatusCode]}>
        {t(`synchronizationStatusCode.${synchronizationStatusCode}`)}
      </span>
    </div>
  );
};

export default GPSyncStatus;
