import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { IFloatingFilterParams } from "@ag-grid-community/core";
import classnames from "classnames";

import { DataGridSelectFilter } from "../modules/types";
import { BusinessRole } from "../../../types/domain";
import { MultiSelectInput } from "../../index";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import styles from "./MultiSelectFilter.module.scss";

interface Option {
  label: string;
  value: string;
  parentValue?: string | number;
}

interface Props extends IFloatingFilterParams {
  id: string;
  placeholder: string;
  options: Option[];
  isMultiLevel?: boolean;
  disabled?: boolean;
}

export default forwardRef((props: Props, ref) => {
  const {
    id,
    placeholder = "Any",
    options,
    parentFilterInstance,
    isMultiLevel = false,
    disabled = false,
  } = props;
  const [selected, setSelected] = useState<string[]>([]);

  useUpdateEffect(() => {
    parentFilterInstance((instance) => instance.setModel({ value: selected }));
  }, [selected, parentFilterInstance]);

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged: (parentModel: DataGridSelectFilter) => {
        setSelected(parentModel?.value || []);
      },
    };
  });

  const onChange = (_: any, values: unknown[]) => {
    setSelected(values as BusinessRole[]);
  };

  const selectedPlaceholder = useMemo(() => {
    const selectedCount = selected.length;

    if (selectedCount > 1) {
      return `${selectedCount} selected`;
    }
    return selectedCount === 1
      ? options?.find((item) => item.value === selected[0])?.label
      : placeholder;
  }, [options, selected, placeholder]);

  return (
    <MultiSelectInput
      id={id}
      value={selected}
      options={options}
      onChange={onChange}
      disabled={disabled}
      placeholder={selectedPlaceholder}
      wrapperClassName={styles.selectFilterInput}
      listClassName={classnames(isMultiLevel && styles.selectFilterList)}
    />
  );
});
