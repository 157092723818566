export enum PermissionLevel {
  Deny = "Deny",
  Read = "Read",
  Full = "Full",
}

export interface UserPermission {
  name: string;
  level: PermissionLevel;
}

export type UserPermissionsDictionary = {
  [key: string]: PermissionLevel;
};
