import React, { useContext } from "react";

import UserContext from "contexts/userContext";

import { IconButton } from "fe-shared/src/components";
import { LogoutIcon } from "fe-shared/src/components/Icons";

import * as api from "./modules/api";

import styles from "./UserProfile.module.scss";

const UserProfile = () => {
  const { setData, user } = useContext(UserContext);
  const { firstName = "", lastName = "" } = user?.data || {};

  const onLogoutClick = () => {
    api.userLogout().then(() => {
      setData(null);
    });
  };

  return (
    <div className={styles.userProfile}>
      <span className={styles.userProfileName}>
        {`${firstName} ${lastName}`}
      </span>
      <IconButton
        aria-label="Logout"
        id="user-profile-logout-button"
        onClick={onLogoutClick}
        className={styles.userProfileLogoutButton}
      >
        <LogoutIcon fillColor={null} />
      </IconButton>
    </div>
  );
};

export default UserProfile;
