import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import styles from "./FormControlDropdown.module.scss";

interface Option {
  label: string;
  value: string | number;
}

interface Props<T> {
  wrapperRef: React.RefObject<HTMLUListElement>;
  value?: string | number;
  selectedIdx: number;
  options: T[];
  onChange: (
    e: React.MouseEvent,
    value: string | number,
    label: string
  ) => void;
  getOption: (option: T, idx: number) => ReactNode;
  minWidth?: number;
  wrapperClassName?: string;
  optionClassName?: string;
}

const FormControlDropdown = <T extends Option>(props: Props<T>) => {
  const {
    wrapperRef,
    value,
    selectedIdx,
    options,
    onChange,
    getOption,
    minWidth = 400,
    wrapperClassName,
    optionClassName,
  } = props;
  const { t } = useTranslation();

  return (
    <ul
      ref={wrapperRef}
      style={{ minWidth, maxWidth: minWidth * 1.5 }}
      className={classnames(
        styles.formControlDropdownOptionWrapper,
        wrapperClassName
      )}
    >
      {options.map((item, index) => (
        <li
          key={item.value}
          className={classnames(
            styles.formControlDropdownOptionWrapperItem,
            {
              [styles.active]: item.value === value,
              [styles.selected]: index === selectedIdx,
            },
            optionClassName
          )}
          onClick={(e) => onChange(e, item.value, item.label)}
          aria-hidden="true"
        >
          {getOption(item, index)}
        </li>
      ))}
      {!options.length && (
        <li
          className={classnames(
            styles.formControlDropdownOptionWrapperItem,
            styles.formControlDropdownOptionWrapperEmpty
          )}
        >
          {t("formDropdownNoMatches")}
        </li>
      )}
    </ul>
  );
};

export default FormControlDropdown;
