import React from "react";

import { IconProps } from "../../types/common";

const SupportingDocsIcon = ({
  width,
  height,
  style,
  className,
  fillClassName,
  fillColor,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M14.82 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C4.86 21 4.73 20.99 4.6 20.97C4.21 20.89 3.86 20.69 3.59 20.42C3.41 20.23 3.26 20.02 3.16 19.78C3.06 19.54 3 19.27 3 19V5C3 4.72 3.06 4.46 3.16 4.23C3.26 3.99 3.41 3.77 3.59 3.59C3.86 3.32 4.21 3.12 4.6 3.04C4.73 3.01 4.86 3 5 3H9.18C9.6 1.84 10.7 1 12 1C13.3 1 14.4 1.84 14.82 3ZM6.99999 7.00005H17V9.00005H6.99999V7.00005ZM17 11H6.99999V13H17V11ZM14 15H6.99999V17H14V15ZM12 2.75004C12.41 2.75004 12.75 3.09004 12.75 3.50004C12.75 3.91004 12.41 4.25004 12 4.25004C11.59 4.25004 11.25 3.91004 11.25 3.50004C11.25 3.09004 11.59 2.75004 12 2.75004ZM5 19H19V5H5V19Z"
    />
  </svg>
);

SupportingDocsIcon.defaultProps = {
  width: "24",
  height: "24",
  fillColor: "#fff",
};

export default SupportingDocsIcon;
