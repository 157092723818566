import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Company } from "fe-shared/src/types/domain";
import { Loader } from "fe-shared/src/components";

interface Props {
  companies: Company[];
  currentCompany: Company;
  changeCompany: (company: Company) => void;
}

const CompanyChecker = (props: Props) => {
  const { companies, currentCompany, changeCompany } = props;
  const history = useHistory();
  const { 0: returnPath, id: companyId } = useParams<{
    id: string;
    0: string;
  }>();

  useEffect(() => {
    const companyIdNumber = Number(companyId);

    if (currentCompany.companyId !== companyIdNumber) {
      const selectedCompany = companies.find(
        (c) => c.companyId === companyIdNumber
      );

      changeCompany(selectedCompany!);
    } else {
      history.push(`/${returnPath}`);
    }
  }, [
    companies,
    companyId,
    returnPath,
    currentCompany,
    changeCompany,
    history,
  ]);

  return <Loader isBlocking isFullScreen />;
};

export default CompanyChecker;
