import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const ForgotPasswordView = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.url} exact>
        <ForgotPassword />
      </Route>
      <Route path={`${match.url}/:resetPasswordToken`}>
        <ResetPassword />
      </Route>
    </Switch>
  );
};

export default ForgotPasswordView;
