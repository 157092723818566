import axios from "axios";
import { useQuery } from "react-query";

enum ConfigSettingsCode {
  NoteLengthLimit = "max-note-characters-number",
}

const { NoteLengthLimit } = ConfigSettingsCode;

interface ConfigSettingRaw {
  settingCode: ConfigSettingsCode;
  settingValue: string;
}

interface ConfigSettings {
  noteLengthLimit: number;
}

const DEFAULT_SETTINGS: ConfigSettings = {
  noteLengthLimit: 10000,
};

const getSettingValue = (
  settings: ConfigSettingRaw[],
  settingCode: ConfigSettingsCode,
  key: keyof ConfigSettings
) => {
  const rawValue = settings.find((s) => s.settingCode === settingCode)
    ?.settingValue;
  const value = rawValue ? parseInt(rawValue, 10) : 0;

  return value || DEFAULT_SETTINGS[key];
};

const getMappedSettings = (settings: ConfigSettingRaw[]): ConfigSettings => ({
  noteLengthLimit: getSettingValue(
    settings,
    NoteLengthLimit,
    "noteLengthLimit"
  ),
});

const getConfigSettings = () =>
  axios
    .get<ConfigSettingRaw[]>("configurationSettings")
    .then((res) => getMappedSettings(res.data));

const useConfigSettings = () => {
  const { data } = useQuery("configSettings", getConfigSettings, {
    staleTime: Infinity,
  });

  return data || DEFAULT_SETTINGS;
};

export default useConfigSettings;
