import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useParams, useHistory } from "react-router-dom";

import { PasswordApiError } from "components/PasswordErrorBanner";
import { LinkButton, Loader, LogoLayout } from "fe-shared/src/components";
import UserContext from "contexts/userContext";

import { ApiError } from "fe-shared/src/types/common";
import {
  getTokenErrorInfo,
  getExistingUserRedirectRoute,
} from "helpers/tokenErrors";

import * as api from "api/token";

import { ROUTES } from "consts";
import { TokenErrorCodes, TokenType } from "types";

import styles from "./AcceptWebInviteView.module.scss";

const AcceptWebInviteView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, setData } = useContext(UserContext);
  const [companyName, setCompanyName] = useState("");
  const [isSameUserLoggedIn, setIsSameUserLoggedIn] = useState(false);

  const { acceptInviteToken } = useParams<{
    acceptInviteToken: string;
  }>();

  const { data: userEmail, isLoading: isInitialLoading } = useQuery<
    string,
    AxiosError<PasswordApiError>
  >(
    ["acceptWebInviteValidation", acceptInviteToken, TokenType.WebInvite],
    api.getTokenInformation,
    {
      onSuccess: (email) => {
        setIsSameUserLoggedIn(email === user?.data?.email);
      },
      onError: (error) => {
        const { email, tokenErrorCode } = getTokenErrorInfo(error);
        const isSameUser = email === user?.data?.email;
        const isTokenUsed = tokenErrorCode === TokenErrorCodes.TokenReusage;

        if (isTokenUsed && isSameUser) {
          history.replace(ROUTES.root);
        } else if (isTokenUsed && !isSameUser) {
          setData(null);
          history.replace(ROUTES.login);
        } else {
          history.replace(getExistingUserRedirectRoute(error));
        }
      },
    }
  );

  const { isError, isSuccess, isLoading } = useQuery<
    api.AcceptInvitationResponse,
    AxiosError<ApiError>
  >(
    ["acceptWebInvite", acceptInviteToken, TokenType.WebInvite],
    api.acceptInvitationAnonymously,
    {
      enabled: !!userEmail,
      onSuccess: ({ companyId, displayName }) => {
        setCompanyName(displayName);
        if (user) {
          setData({
            ...user,
            lastVisitedCompanyId: companyId,
            lastVisitedCompanyName: displayName,
          });
        }
      },
    }
  );

  if (isInitialLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      {isSuccess && (
        <>
          <h2 className={styles.header}>{t("appInvite.acceptedTitle")}</h2>
          <p className={styles.description}>
            {t("appInvite.webDescription", {
              companyName,
            })}
          </p>
          <LinkButton
            className={styles.link}
            id="navigate-link-button"
            to={isSameUserLoggedIn ? ROUTES.root : ROUTES.login}
          >
            {t(`appInvite.${isSameUserLoggedIn ? "goToCompany" : "goToLogin"}`)}
          </LinkButton>
        </>
      )}
      {isError && (
        <>
          <h2 className={styles.header}>{t("appInvite.errorTitle")}</h2>
          <p className={styles.description}>
            {t("appInvite.errorDescription")}
          </p>
        </>
      )}
    </LogoLayout>
  );
};

export default AcceptWebInviteView;
