import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "contexts/userContext";

import { Button, LogoLayout } from "fe-shared/src/components";
import * as api from "../UserProfile/modules/api";

import styles from "./NoCompaniesPage.module.scss";

export interface Props {
  title?: string;
}

const NoCompaniesPage = (props: Props) => {
  const { title = "chooseCompany.noCompaniesError" } = props;
  const { t } = useTranslation();
  const { setData } = useContext(UserContext);

  const onLogout = () => {
    api.userLogout().then(() => setData(null));
  };

  return (
    <LogoLayout>
      <h2 className={styles.title}>{t(title)}</h2>
      <p className={styles.description}>
        {t("chooseCompany.noCompaniesDescription")}
      </p>
      <Button id="no-companies-user-logout-button" onClick={onLogout}>
        {t("chooseCompany.logout")}
      </Button>
    </LogoLayout>
  );
};

export default NoCompaniesPage;
