import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IDoesFilterPassParams, IFilterParams } from "@ag-grid-community/core";

import { DataGridTextFilter } from "../modules/types";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

interface Props extends IFilterParams {
  doesFilterPass?: (value: string, params: IDoesFilterPassParams) => boolean;
  startFilteringThreshold?: number;
}

export default forwardRef((props: Props, ref) => {
  const {
    filterChangedCallback,
    doesFilterPass,
    startFilteringThreshold = 0,
  } = props;
  const [text, setText] = useState("");
  const refInput = useRef(null);

  useUpdateEffect(() => {
    let timeout: NodeJS.Timeout;

    if (text.length > startFilteringThreshold || text.length === 0) {
      timeout = setTimeout(() => {
        filterChangedCallback();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [text, filterChangedCallback, startFilteringThreshold]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive: () => {
        return !!text;
      },
      doesFilterPass: (params: IDoesFilterPassParams) => {
        if (doesFilterPass) {
          return doesFilterPass(text, params.data);
        }
        return true;
      },
      getModel: () => ({ type: "textFilter", value: text }),
      setModel: (model: DataGridTextFilter) => {
        setText(model?.value || "");
      },
    };
  });

  return (
    <input
      value={text}
      ref={refInput}
      onChange={(e) => setText(e.target.value)}
    />
  );
});
