import React from "react";
import classnames from "classnames";
import ReactMarkdown from "react-markdown";
import Tooltip from "../Tooltip";

import styles from "./FormControlLabel.module.scss";

interface Props {
  id: string;
  title?: React.ReactNode;
  tooltipText?: string;
  required?: boolean;
  className?: string;
}

const FormControlLabel = (props: Props) => {
  const { id, title, tooltipText, required, className } = props;

  if (!title) {
    return null;
  }

  return (
    <label
      htmlFor={id}
      className={classnames(styles.formControlLabelDefault, className)}
    >
      <div
        className={classnames({ [styles.formControlLabelRequired]: required })}
      >
        {title}
      </div>
      {!!tooltipText && (
        <Tooltip className={styles.formControlLabelTooltip}>
          <ReactMarkdown source={tooltipText} />
        </Tooltip>
      )}
    </label>
  );
};

export default FormControlLabel;
