import { useEffect, useRef, RefObject } from "react";

function useOnClickOutside(
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: () => void,
  isActive = true
): void {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener = (event: any) => {
      const refs = Array.isArray(ref) ? ref : [ref];
      const isClickOutside = !refs.some(
        (r) => !r.current || r.current.contains(event.target)
      );

      if (isClickOutside) {
        handlerRef.current?.();
      }
    };

    if (isActive) {
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
    }

    return () => {
      if (isActive) {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      }
    };
  }, [ref, handlerRef, isActive]);
}

export default useOnClickOutside;
