import JoiBase, { StringSchema, DateSchema } from "@hapi/joi";
import JoiDate from "@hapi/joi-date";
import merge from "lodash/merge";
import pick from "lodash/pick";
import { TFunction } from "i18next";

import { DirtyKeys, FileInterface, FileStatus } from "../types/common";

function isStringSchema(arg: any = {}): arg is StringSchema {
  return arg.type === "string";
}

function isDateSchema(arg: any = {}): arg is DateSchema {
  return arg.type === "date";
}

export const Joi = JoiBase.defaults((schema) => {
  if (isStringSchema(schema)) {
    return schema.trim().max(256);
  }
  if (isDateSchema(schema)) {
    return schema.raw().greater("1900-01-01");
  }
  return schema;
}).extend(JoiDate);

export const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;
export const phoneJoiSchema = Joi.string().pattern(PHONE_REGEX, {
  name: "phone",
});

export const PHONE_EXT_REGEX = /^\d+$/;
export const phoneExtJoiSchema = Joi.string().pattern(PHONE_EXT_REGEX).max(12);

export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!;=`~'+_@#$%^&*()[\],.?\\":{}|<>/\-\\]).{8,20}$/;
export const passwordJoiSchema = Joi.string().pattern(PASSWORD_REGEXP);

export const emailJoiSchema = Joi.string().email({ tlds: { allow: false } });

export const NUMERIC_REGEX = /^\d+$/;
export const numericJoiSchema = Joi.string().pattern(NUMERIC_REGEX, {
  name: "numeric",
});

export const CURRENCY_REGEX = /^\d+(\.\d{1,2})?$/;
export const currencyJoiSchema = Joi.string().pattern(CURRENCY_REGEX, {
  name: "currency",
});

export const PERCENTS_REGEX = /^((\d{1,2}(\.\d{1,2})?)|100(\.0{1,2}$)?)$/;
export const percentsJoiSchema = Joi.string().pattern(PERCENTS_REGEX, {
  name: "percents",
});

export const SCALE_TICKET_REGEX = /^[0-9a-zA-Z\-\s_#,:;.]{1,50}$/;
export const scaleTicketSchema = Joi.string().pattern(SCALE_TICKET_REGEX, {
  name: "scaleTicket",
});

export const getAttachmentsSchema = (name: string) =>
  Joi.array().when(
    Joi.ref(`/${name}`, {
      adjust: (files: FileInterface[]) =>
        files.some((f) => !!f.status && f.status !== FileStatus.Loaded),
    }),
    {
      is: Joi.valid(true),
      then: Joi.forbidden(),
    }
  );

export const getNumericErrors = (
  requiredError: string,
  invalidError: string
) => ({
  "number.base": requiredError,
  "number.min": invalidError,
  "number.integer": invalidError,
  "number.unsafe": invalidError,
  "number.max": invalidError,
});

export const getDirtyKeys = <P extends Record<string, any>>(
  content: P,
  prefix: string = "",
  result: DirtyKeys = {}
) => {
  Object.keys(content).forEach((key) => {
    const currentValue = content[key];

    if (Array.isArray(currentValue) && typeof currentValue[0] === "object") {
      currentValue.forEach((item, index) => {
        getDirtyKeys(item, `${prefix}${key}[${index}].`, result);
      });
    } else if (
      !Array.isArray(currentValue) &&
      typeof currentValue === "object" &&
      currentValue !== null
    ) {
      getDirtyKeys(currentValue, `${prefix}${key}.`, result);
    } else {
      result[`${prefix}${key}`] = false;
    }
  });
  return result;
};

export const getMergedContent = <T extends { [key: string]: unknown }>(
  initialContent: T,
  currentContent: { [key: string]: unknown }
): T =>
  merge({}, initialContent, pick(currentContent, Object.keys(initialContent)));

export const getDefaultErrorMessages = (
  t: TFunction
): Record<string, string> => ({
  "string.max": t("validation.stringIsTooLong"),
  "date.greater": t("validation.dateIsLess"),
});

export default Joi;
