import axios from "axios";

export const userLogout = (): Promise<boolean> => {
  return axios
    .post("/account/revokeToken", {
      withCredentials: true,
    })
    .then(() => true)
    .catch(() => false);
};

export default userLogout;
