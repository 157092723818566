export enum TokenErrorCodes {
  TokenExpiration = "TokenExpiration",
  TokenReusage = "TokenReusage",
  TokenRevoked = "TokenRevoked",
  PasswordCreated = "PasswordWasCreated",
  InactiveEmployee = "InactiveEmployee",
}

export enum TokenType {
  CreatePin = "create-pin",
  CreatePassword = "create-password",
  ResetPassword = "forgot-password",
  WebInvite = "web-invite",
  MobileInvite = "mobile-invite",
}
