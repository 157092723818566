export const handleMouseDown = (
  e: React.MouseEvent<any, MouseEvent>,
  handler?: (e: React.MouseEvent<any, MouseEvent>) => void
) => {
  e.preventDefault();
  e.currentTarget.focus();
  e.currentTarget.blur();
  handler?.(e);
};

export const handleKeyDown = (
  e: React.KeyboardEvent<any>,
  onClick: (e: React.KeyboardEvent<any>) => void,
  handler?: (e: React.KeyboardEvent<any>) => void
) => {
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault();
    onClick(e);
  }
  handler?.(e);
};
