import React, { useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useParams, useHistory } from "react-router-dom";

import { PasswordApiError } from "components/PasswordErrorBanner";
import { Loader, LogoLayout } from "fe-shared/src/components";

import { ApiError } from "fe-shared/src/types/common";
import {
  getTokenErrorInfo,
  getExistingUserRedirectRoute,
} from "helpers/tokenErrors";

import * as api from "api/token";

import config from "config";
import { TokenErrorCodes, TokenType } from "types";
import mobileAppImage from "assets/img/mobile-app.png";
import { ReactComponent as PointArrow } from "assets/img/point-arrow.svg";

import styles from "./AcceptMobileInviteView.module.scss";

const AcceptMobileInviteView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [acceptedCompanyName, setAcceptedCompanyName] = useState("");

  const { acceptInviteToken } = useParams<{
    acceptInviteToken: string;
  }>();

  const { data: userEmail, isLoading: isInitialLoading } = useQuery<
    string,
    AxiosError<PasswordApiError>
  >(
    ["acceptMobileInviteValidation", acceptInviteToken, TokenType.MobileInvite],
    api.getTokenInformation,
    {
      onError: (error) => {
        const { tokenErrorCode } = getTokenErrorInfo(error);
        const isTokenUsed = tokenErrorCode === TokenErrorCodes.TokenReusage;

        if (isTokenUsed) {
          window.location.replace(config.MOBILE_APP_DOWNLOAD_URL);
        } else {
          history.replace(getExistingUserRedirectRoute(error));
        }
      },
    }
  );

  const { isError, isSuccess, isLoading } = useQuery<
    api.AcceptInvitationResponse,
    AxiosError<ApiError>
  >(
    ["acceptMobileInvite", acceptInviteToken, TokenType.MobileInvite],
    api.acceptInvitationAnonymously,
    {
      enabled: !!userEmail,
      onSuccess: ({ displayName }) => {
        setAcceptedCompanyName(displayName);
      },
    }
  );

  if (isInitialLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      {isSuccess && (
        <>
          <h2 className={styles.header}>{t("appInvite.acceptedTitle")}</h2>
          <p className={styles.description}>
            {t("appInvite.mobileDescription", {
              companyName: acceptedCompanyName,
            })}
          </p>
          <img
            src={mobileAppImage}
            className={styles.image}
            alt={t("appInvite.mobileImageAlt")}
          />
          <PointArrow className={styles.arrow} />
        </>
      )}
      {isError && (
        <>
          <h2 className={styles.header}>{t("appInvite.errorTitle")}</h2>
          <p className={styles.description}>
            {t("appInvite.errorDescription")}
          </p>
        </>
      )}
    </LogoLayout>
  );
};

export default AcceptMobileInviteView;
