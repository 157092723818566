import React, { useState, useRef, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";
import debounce from "lodash/debounce";
import { CSSTransition } from "react-transition-group";

import { CloseIcon } from "../Icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import styles from "./Dialog.module.scss";

export interface Props {
  modal?: boolean;
  title: React.ReactNode;
  width?: number | string;
  actions?: React.ReactNode[];
  onClose?: () => void;
  isActive: boolean;
  children: React.ReactNode;
  isWithTopLine?: boolean;
  isWithBottomLine?: boolean;
  isHeaderHidden?: boolean;
}

const defaultProps = {
  actions: [],
  onClose: () => {},
};

const classNames = {
  enter: styles.etDialogEnter,
  enterActive: styles.etDialogEnterActive,
  exit: styles.etDialogLeave,
  exitActive: styles.etDialogLeaveActive,
};

let dialogRoot = document.getElementById("root");

if (!dialogRoot) {
  dialogRoot = document.createElement("div");
  dialogRoot.setAttribute("id", "root");
  document.body.appendChild(dialogRoot);
}

const Dialog = (props: Props) => {
  const {
    title,
    modal = false,
    width = 480,
    actions = defaultProps.actions,
    onClose = defaultProps.onClose,
    isActive,
    children,
    isWithTopLine = false,
    isWithBottomLine = false,
    isHeaderHidden = false,
  } = props;
  const transitionRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const dialogWindowRef = useRef<HTMLDivElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const [isWithLines, setIsWithLines] = useState(false);

  // eslint-disable-next-line
  const showLines = useCallback(
    debounce(
      () => {
        const { clientHeight, scrollHeight } = dialogContentRef.current!;

        setIsWithLines(scrollHeight > clientHeight);
      },
      100,
      { leading: true }
    ),
    []
  );

  useEffect(() => {
    const dialogContent = dialogContentRef.current!;

    if (isActive) {
      resizeObserverRef.current = new ResizeObserver(showLines);
      resizeObserverRef.current.observe(dialogContent);

      const firstControl = controlsRef.current?.firstChild as HTMLElement;

      if (firstControl) {
        firstControl.focus();
      } else {
        closeButtonRef.current?.focus();
      }
    }

    return () => {
      showLines.cancel();
      resizeObserverRef.current?.unobserve(dialogContent);
    };
  }, [isActive, showLines]);

  useOnClickOutside(dialogWindowRef, onClose, isActive && !modal);

  return createPortal(
    <CSSTransition
      id="dialog-node"
      data-testid="dialog-node"
      mountOnEnter
      unmountOnExit
      in={isActive}
      timeout={220}
      classNames={classNames}
      nodeRef={transitionRef}
    >
      <div ref={transitionRef} className={styles.etDialog}>
        <div
          style={{ width }}
          ref={dialogWindowRef}
          className={styles.etDialogWindow}
        >
          <div
            className={classnames(
              styles.etDialogHeader,
              isHeaderHidden && styles.etDialogHeaderHidden,
              (isWithLines || isWithTopLine) && styles.etDialogHeaderShadowed
            )}
          >
            <div className={styles.etDialogHeaderTitle}>{title}</div>
            <button
              type="button"
              onClick={onClose}
              ref={closeButtonRef}
              id="close-dialog-btn"
              data-testid="close-dialog-btn"
              aria-label="Close Dialog"
              className={styles.etDialogCloseButton}
            >
              <CloseIcon fillColor={null} />
            </button>
          </div>
          <div
            ref={dialogContentRef}
            className={classnames(styles.etDialogContent, {
              [styles.etDialogContentActionless]:
                !actions.length && !isHeaderHidden,
              [styles.etDialogContentHeaderless]:
                !!actions.length && isHeaderHidden,
              [styles.etDialogContentActionAndHeaderless]:
                !actions.length && isHeaderHidden,
            })}
          >
            {children}
          </div>
          {!!actions.length && (
            <div
              ref={controlsRef}
              className={classnames(
                styles.etDialogControls,
                (isWithLines || isWithBottomLine) &&
                  styles.etDialogControlsShadowed
              )}
            >
              {React.Children.toArray(actions)}
            </div>
          )}
        </div>
      </div>
    </CSSTransition>,
    dialogRoot!
  );
};

export default Dialog;
