import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import classnames from "classnames";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import subWeeks from "date-fns/subWeeks";
import addWeeks from "date-fns/addWeeks";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import subYears from "date-fns/subYears";
import addYears from "date-fns/addYears";
import differenceInDays from "date-fns/differenceInDays";
import ReactDatePicker from "react-datepicker";
import Tippy, { TippyProps } from "@tippyjs/react";

import { getOffsetDate, getNoOffsetDate } from "../../helpers/dates";

import TextInput from "../TextInput";

import IconButton from "../IconButton";
import { RemoveIcon } from "../Icons";
import styles from "./DatePicker.module.scss";
import "react-datepicker/dist/react-datepicker-min.module.css";

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

interface Props {
  id: string;
  placeholder: string;
  startDate: Date | null;
  endDate?: Date | null;
  className?: string;
  monthsShown?: number;
  maxDate?: Date;
  minDate?: Date;
  setStartDate: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  isNoOffset?: boolean;
  isRangeSelect?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
  isError?: boolean;
  errorText?: string;
  hintText?: string;
  title?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  valueFormat?: string;
  isClearButton?: boolean;
}

const DatePicker = (props: Props) => {
  const {
    id,
    className,
    placeholder = "Select dates",
    monthsShown = 1,
    maxDate,
    minDate,
    setStartDate,
    setEndDate,
    isNoOffset = false,
    isRangeSelect = false,
    inputClassName,
    inputWrapperClassName,
    disabled,
    startDate,
    endDate,
    valueFormat = "dd/MM/yy",
    isClearButton = true,
    ...rest
  } = props;
  const isWithoutHours = (date: Date | null = null) => {
    const time = date?.toISOString().substr(11, 8);

    return time === "00:00:00" || time === "23:59:59";
  };

  const getExternalDate = (date: Date | null = null) =>
    isNoOffset && !isWithoutHours(date) ? getNoOffsetDate(date) : date;

  const getInternalDate = (date: Date | null = null) =>
    isNoOffset && isWithoutHours(date) ? getOffsetDate(date) : date;

  const internalStartDate = getInternalDate(startDate);
  const internalEndDate = getInternalDate(endDate);

  const [lastDateSelected, setLastDateSelected] = useState<Date | null>(null);
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [clickedCounter, setClickedCounter] = useState(0);
  const [isDatePickerOpened, setDatePickerOpened] = useState(false);
  const tippyRef = useRef<any>(null);
  const datePickerRef = useRef<any>(null);

  const updateTippy = useCallback(() => {
    setTimeout(() => {
      tippyRef?.current?._tippy?.popperInstance?.update();
    });
  }, [tippyRef]);

  useEffect(() => {
    window.addEventListener("resize", updateTippy);

    return () => {
      window.removeEventListener("resize", updateTippy);
    };
  }, [updateTippy]);

  const inputText = useMemo(() => {
    const dates = isRangeSelect
      ? [internalStartDate, internalEndDate]
      : [internalStartDate];
    const formattedDates = dates.reduce((acc, item) => {
      if (item) {
        acc = [...acc, format(item, valueFormat)];
      }

      return acc;
    }, [] as string[]);

    return formattedDates.join(" - ");
  }, [valueFormat, isRangeSelect, internalStartDate, internalEndDate]);

  const openDatePicker = () => {
    if (!isDatePickerOpened) {
      setDatePickerOpened(true);
      datePickerRef?.current?.setOpen(true);
    }
  };
  const closeDatePicker = () => {
    setDatePickerOpened(false);
    setClickedCounter(0);
    datePickerRef?.current?.setOpen(false);
  };

  const onDayMouseEnter = (day: Date) => {
    setHoveredDate(day);
  };

  const onDateChange = (date: Date | null) => {
    if (!date) {
      setStartDate(null);

      return;
    }

    setStartDate(getExternalDate(startOfDay(date)));
    setLastDateSelected(date);
    closeDatePicker();
  };

  const onRangeChange = (date: Date | null, type: string | null) => {
    if (!date) {
      setStartDate(null);
      setEndDate!(null);

      return;
    }

    if (type && internalStartDate) {
      if (isBefore(date, internalStartDate)) {
        setStartDate(getExternalDate(startOfDay(date)));
      } else {
        setEndDate!(getExternalDate(endOfDay(date)));
      }
    } else {
      setStartDate(getExternalDate(startOfDay(date)));
      setEndDate!(getExternalDate(endOfDay(date)));
    }

    if (clickedCounter) {
      closeDatePicker();
    } else {
      setClickedCounter(clickedCounter + 1);
    }
    setLastDateSelected(date);
  };

  const currentSelection = useMemo(() => {
    if (internalEndDate && internalStartDate && hoveredDate && clickedCounter) {
      const dayDiff = differenceInDays(internalEndDate, internalStartDate);
      const middleDiffDay = addDays(internalStartDate, Math.round(dayDiff / 2));
      const isDayBeforeMiddle = isBefore(hoveredDate, middleDiffDay);

      return {
        date: isDayBeforeMiddle ? internalEndDate : internalStartDate,
        type: isDayBeforeMiddle ? "start" : "end",
      };
    }

    return {
      date: null,
      type: null,
    };
  }, [internalStartDate, internalEndDate, hoveredDate, clickedCounter]);

  const onDayKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const eventKey = event.key;

    let newSelection;

    switch (eventKey) {
      case "ArrowLeft":
        newSelection = subDays(lastDateSelected!, 1);
        break;
      case "ArrowRight":
        newSelection = addDays(lastDateSelected!, 1);
        break;
      case "ArrowUp":
        newSelection = subWeeks(lastDateSelected!, 1);
        break;
      case "ArrowDown":
        newSelection = addWeeks(lastDateSelected!, 1);
        break;
      case "PageUp":
        newSelection = subMonths(lastDateSelected!, 1);
        break;
      case "PageDown":
        newSelection = addMonths(lastDateSelected!, 1);
        break;
      case "Home":
        newSelection = subYears(lastDateSelected!, 1);
        break;
      case "End":
        newSelection = addYears(lastDateSelected!, 1);
        break;
      case "Escape":
        newSelection = lastDateSelected;
        closeDatePicker();
        break;
      default:
        newSelection = lastDateSelected;
        break;
    }

    setLastDateSelected(newSelection);
    setHoveredDate(newSelection);
  };

  const onInputKeyDown = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const eventKey = event.key;

    switch (eventKey) {
      case "Enter":
        openDatePicker();
        break;

      case "Escape":
        closeDatePicker();
        break;

      default:
        break;
    }
  };

  const onInputKeyUp = (event: React.KeyboardEvent) => {
    event.preventDefault();
    const allowedKeys = ["ArrowUp", "ArrowDown"];

    if (isDatePickerOpened && allowedKeys.includes(event.key)) {
      datePickerRef?.current?.onInputKeyDown(event);
    }
  };

  const clearButtonComponent = (
    <IconButton
      id={`clear-${id}`}
      aria-label="Clear"
      onClick={() => {
        if (isRangeSelect) {
          onRangeChange(null, null);
        } else {
          onDateChange(null);
        }
      }}
      className={styles.dateRangeClearButton}
    >
      <RemoveIcon fillColor={null} />
    </IconButton>
  );

  const datePickerProps = isRangeSelect
    ? {
        selected: currentSelection.date,
        endDate: internalEndDate,
        selectsStart: currentSelection.type === "start",
        selectsEnd: currentSelection.type === "end",
        onChange: (date: Date) => onRangeChange(date, currentSelection.type),
      }
    : {
        selected: internalStartDate,
        selectsStart: true,
        onChange: (date: Date) => onDateChange(date),
      };

  const datePickerContent = (
    <ReactDatePicker
      id={id}
      ref={datePickerRef}
      calendarClassName={classnames(className, styles.dateRangeCalendar)}
      startDate={internalStartDate}
      onDayMouseEnter={onDayMouseEnter}
      onKeyDown={onDayKeyDown}
      monthsShown={monthsShown}
      maxDate={maxDate}
      minDate={minDate}
      inline
      {...datePickerProps}
    />
  );

  return (
    <div>
      <Tippy
        interactive
        arrow={false}
        ref={tippyRef}
        offset={[0, 2]}
        placement="bottom"
        visible={isDatePickerOpened}
        appendTo={() => document.body}
        content={datePickerContent}
        className={styles.dateRangeTooltip}
        onClickOutside={closeDatePicker}
        popperOptions={popperOptions}
      >
        <TextInput
          id={`text-input-date-picker-${id}`}
          wrapperClassName={inputWrapperClassName}
          inputClassName={classnames(styles.dateRangeTextInput, inputClassName)}
          value={inputText}
          disabled={disabled}
          placeholder={placeholder}
          onChange={openDatePicker}
          onClick={openDatePicker}
          onKeyDown={onInputKeyDown}
          onKeyUp={onInputKeyUp}
          onFocus={openDatePicker}
          inputIconContent={
            isClearButton && inputText && !disabled && clearButtonComponent
          }
          {...rest}
        />
      </Tippy>
    </div>
  );
};

export default DatePicker;
