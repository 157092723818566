import React from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import LoginRequirements from "components/LoginRequirements";
import PasswordErrorBanner, {
  PasswordApiError,
} from "components/PasswordErrorBanner";
import { Button, PasswordInput, Loader, LogoLayout } from "fe-shared/src/components";

import { useParams, useHistory } from "react-router-dom";

import useLogin from "hooks/useLogin";
import useValidation from "fe-shared/src/hooks/useValidation";
import {
  getResetPasswordRedirectRoute,
  getTokenErrorInfo,
} from "helpers/tokenErrors";

import { ErrorReasons } from "components/BrokenLink";
import { ROUTES } from "consts";
import { TokenErrorCodes, TokenType } from "types";

import * as api from "api/password";
import { getTokenInformation } from "api/token";

import { FORM_CONFIGURATION, errorMessages } from "./modules/config";

import styles from "./ResetPassword.module.scss";

const ResetPassword = () => {
  const history = useHistory();

  const { resetPasswordToken } = useParams<{
    resetPasswordToken: string;
  }>();

  const { t } = useTranslation();
  const { validationConfig, initialContent } = FORM_CONFIGURATION;

  const { values, errors, updateValue, validateValue, isValid } = useValidation(
    validationConfig,
    initialContent,
    errorMessages
  );

  const { data: userEmail, isLoading: isInitialLoading } = useQuery<
    string,
    AxiosError<PasswordApiError>
  >(
    ["resetPasswordEmail", resetPasswordToken, TokenType.ResetPassword],
    getTokenInformation,
    {
      onError: (error) => {
        history.replace(getResetPasswordRedirectRoute(error));
      },
    }
  );

  const [login, mutationResultLogin] = useLogin();

  const [reset, mutationResultReset] = useMutation<
    api.SetPasswordResponse,
    AxiosError<PasswordApiError>,
    api.SetPasswordParams
  >(api.setPassword, {
    onSuccess: () => {
      login({
        email: userEmail!,
        password: values.password,
      });
    },
    onError: (error) => {
      const { tokenErrorCode } = getTokenErrorInfo(error);

      if (tokenErrorCode === TokenErrorCodes.TokenExpiration)
        history.push(`${ROUTES.brokenLink}/${ErrorReasons.Expired}`);
    },
  });

  const mutationResult = mutationResultReset.isError
    ? mutationResultReset
    : mutationResultLogin;

  const { isError, isLoading } = mutationResult;

  const resetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValid) {
      reset({
        ...values,
        token: resetPasswordToken,
        email: userEmail!,
        isFirstLogin: false,
      });
    }
  };

  if (isInitialLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <LogoLayout>
      {isLoading && <Loader isBlocking />}
      <h1>{t("resetPassword.title")}</h1>
      <p className={styles.etResetPasswordDescription}>
        {t("resetPassword.description")}
      </p>
      {isError && <PasswordErrorBanner requestStatus={mutationResult} />}
      <form
        onSubmit={resetPassword}
        aria-label={t("login.form")}
        className={styles.etResetPasswordControls}
      >
        <PasswordInput
          name="password"
          id="password-input"
          autoComplete="off"
          title={t("resetPassword.passwordCreate")}
          value={values.password}
          isError={!!errors.password}
          errorText={errors.password}
          onBlur={() => validateValue("password")}
          inputClassName={styles.etResetPasswordInput}
          onChange={(e) => updateValue("password", e.target.value)}
        />
        <PasswordInput
          name="confirmedPassword"
          id="confirmed-password-input"
          autoComplete="off"
          title={t("resetPassword.passwordConfirm")}
          value={values.confirmedPassword}
          isError={!!errors.confirmedPassword}
          errorText={errors.confirmedPassword}
          inputClassName={styles.etResetPasswordInput}
          onBlur={() => validateValue("confirmedPassword")}
          onChange={(e) => updateValue("confirmedPassword", e.target.value)}
        />

        <div>
          <Button
            type="submit"
            id="start-application-button"
            className={styles.etResetPasswordButton}
            disabled={
              !values.confirmedPassword ||
              !values.password ||
              !!Object.keys(errors).length ||
              isError
            }
          >
            {t("resetPassword.sendButton")}
          </Button>
        </div>
      </form>
      <LoginRequirements />
    </LogoLayout>
  );
};

export default ResetPassword;
