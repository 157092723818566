import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import formatTransactionNumber from "../../../helpers/format";
import {
  TransactionCategory,
  TransactionRecordsListProps,
} from "../../../types/common";
import { EmptyState, Loader } from "../../index";
import styles from "../RecordsSidebar.module.scss";
import Row from "./Row";

const TRANSACTION_CATEGORY_URL_MAP: { [key: string]: string } = {
  [TransactionCategory.Transactions]: "transactions",
  [TransactionCategory.Transfers]: "transfers",
  [TransactionCategory.InventoryAdjustment]: "inventory-adjustments",
};

const TransactionRecordsList = ({
  entityName,
  queryResult,
  iconComponent,
  translationPrefix,
  transactionOrTransfer,
}: TransactionRecordsListProps) => {
  const { t } = useTranslation();
  const { data: records, isLoading } = queryResult;
  const hasNoRecords = !isLoading && !records?.length;
  const transactionType =
    "transactionType" in transactionOrTransfer
      ? transactionOrTransfer.transactionType
      : transactionOrTransfer.transferType;
  const transactionNumber =
    "transactionNumber" in transactionOrTransfer
      ? transactionOrTransfer.transactionNumber
      : transactionOrTransfer.transferNumber;

  return (
    <div className={styles.transactionRecordsList}>
      {isLoading && <Loader isBlocking />}
      {!!records?.length && (
        <div className={styles.transactionRecordsListContainer}>
          <Row
            key={transactionNumber}
            baseKey={transactionNumber}
            dateString={transactionOrTransfer.completedDate}
            title={t(`transactionType.${transactionType}`)}
            isBorder
            isBold
          >
            {formatTransactionNumber(transactionNumber)}
          </Row>
          {records.map((record, index) => {
            return (
              <Row
                key={record.transactionNumber}
                baseKey={record.transactionNumber}
                title={t(`transactionType.${record.transactionTypeCode}`)}
                dateString={record.completedDate}
                materials={record.materials}
                isBorder={index !== records.length - 1}
                isWithArrow={translationPrefix === "traceForward"}
                isWithReverseArrow={translationPrefix === "traceBackward"}
              >
                <Link
                  key={record.transactionNumber}
                  to={`/${
                    TRANSACTION_CATEGORY_URL_MAP[record.transactionCategory]
                  }/${record.transactionNumber}`}
                >
                  {formatTransactionNumber(record.transactionNumber)}
                </Link>
              </Row>
            );
          })}
        </div>
      )}
      {hasNoRecords && (
        <EmptyState
          iconComponent={iconComponent}
          className={styles.emptyList}
          title={t(`${translationPrefix}.emptyTitle`)}
          subtitle={t(`${translationPrefix}.emptySubtitle`, { entityName })}
        />
      )}
    </div>
  );
};

export default TransactionRecordsList;
