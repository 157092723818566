import React from "react";

import { IconProps } from "../../types/common";

const TransactionsIcon = ({
  width,
  height,
  style,
  className,
  fillClassName,
  fillColor,
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M14.99 9.99954V11.7895C14.99 12.2395 14.45 12.4595 14.14 12.1495L11.36 9.35954C11.17 9.15954 11.17 8.84954 11.36 8.64954L14.14 5.85954C14.45 5.53954 14.99 5.75954 14.99 6.20954V7.99954H21C21.55 7.99954 22 8.44954 22 8.99954C22 9.54954 21.55 9.99954 21 9.99954H14.99ZM3 13.9995H9.01V12.1995C9.01 11.7595 9.55 11.5295 9.86 11.8495L12.64 14.6395C12.83 14.8395 12.83 15.1495 12.64 15.3495L9.86 18.1395C9.55 18.4595 9.01 18.2395 9.01 17.7895V15.9995H3C2.45 15.9995 2 15.5495 2 14.9995C2 14.4495 2.45 13.9995 3 13.9995Z"
    />
  </svg>
);

TransactionsIcon.defaultProps = {
  width: "24",
  height: "24",
  fillColor: "#fff",
};

export default TransactionsIcon;
