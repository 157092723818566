import React from "react";
import { AxiosError } from "axios";
import { MutationResult } from "react-query";
import { useTranslation } from "react-i18next";

import Banner from "../Banner";
import { ApiError } from "../../types/common";

interface Props {
  id: string;
  requestStatus: MutationResult<unknown, AxiosError<ApiError>>;
  children?: React.ReactNode;
  className?: string;
}

const ApiErrorBanner = (props: Props) => {
  const {
    id,
    children,
    className,
    requestStatus: { error, reset },
  } = props;
  const { t } = useTranslation();
  const responseData = error?.response?.data;

  const errorCode = responseData?.errorCode || error?.code;
  const errorMessage = t(`errors.${responseData?.errorCode}`, {
    defaultValue: responseData?.message || t(error?.message!),
  });

  return (
    <Banner
      id={id}
      variant="error"
      onDismiss={reset}
      className={className}
      title={t(`errors.codes.${errorCode}`, {
        defaultValue: t("errors.somethingWrong"),
      })}
    >
      {children || errorMessage}
    </Banner>
  );
};

export default ApiErrorBanner;
