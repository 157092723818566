import React, { useRef, useMemo } from "react";
import classnames from "classnames";
import Tippy, { TippyProps } from "@tippyjs/react";
import useDropdown from "fe-shared/src/hooks/useDropdown";

import { ArrowDown } from "fe-shared/src/components/Icons";
import { Company } from "fe-shared/src/types/domain";
import CompanyLink from "../CompanyLink";

import styles from "./CompaniesSwitcher.module.scss";

export interface Props {
  currentCompany: Company;
  companies: Company[];
  onChooseCompany: (company: Company) => void;
}

const popperOptions: TippyProps["popperOptions"] = {
  strategy: "fixed",
  modifiers: [
    {
      name: "preventOverflow",
    },
  ],
};

const CompaniesSwitcher = (props: Props) => {
  const {
    currentCompany: { regNumber, displayName, companyId },
    companies,
    onChooseCompany,
  } = props;

  const onChange = (
    e: React.SyntheticEvent<Element, Event>,
    newValue: React.ReactText
  ) => {
    const newCompany = companies.find(
      (company) => company.companyId === newValue
    );

    if (newCompany) {
      onChooseCompany(newCompany);
    }
  };

  const ref = useRef<HTMLButtonElement>(null);
  const tippyRef = useRef<any>(null);
  const optionsWrapperRef = useRef<HTMLUListElement>(null);
  const dropdownOptions = useMemo(
    () =>
      companies.map((item) => ({
        value: item.companyId,
        label: item.displayName,
      })),
    [companies]
  );
  const {
    selectedIdx,
    isDropdownOpened,
    changeDropdownState,
    onKeyDown,
  } = useDropdown(ref, optionsWrapperRef, dropdownOptions, onChange, companyId);

  const onCompanyClick = (company: Company) => {
    onChooseCompany(company);
    changeDropdownState(false);
  };

  const isSwitcher = companies.length > 1;

  const content = (
    <ul ref={optionsWrapperRef} className={styles.optionsContainer}>
      {companies.map((item, index) => (
        <li
          key={item.companyId}
          className={classnames({ [styles.selected]: index === selectedIdx })}
        >
          <CompanyLink
            company={item}
            onClick={() => onCompanyClick(item)}
            dropdownView
          />
        </li>
      ))}
    </ul>
  );

  return (
    <Tippy
      interactive
      ref={tippyRef}
      arrow={false}
      offset={[0, 2]}
      placement="bottom-start"
      content={content}
      popperOptions={popperOptions}
      className={styles.switcherTooltip}
      visible={isSwitcher && isDropdownOpened}
    >
      <button
        ref={ref}
        type="button"
        className={styles.switcherButton}
        tabIndex={!isSwitcher ? null! : 0}
        onKeyDown={onKeyDown}
        onClick={() => changeDropdownState(!isDropdownOpened)}
        disabled={!isSwitcher}
      >
        <div className={styles.switcherButtonContent}>
          <span>{regNumber}</span>
          <span>{displayName}</span>
        </div>
        {isSwitcher && <ArrowDown width="18" height="18" fillColor="#63696F" />}
      </button>
    </Tippy>
  );
};

export default CompaniesSwitcher;
