import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useParams, useHistory } from "react-router-dom";

import { PasswordApiError } from "components/PasswordErrorBanner";
import { Loader } from "fe-shared/src/components";

import {
  getTokenErrorInfo,
  getNewUserRedirectRoute,
} from "helpers/tokenErrors";

import * as api from "api/token";

import config from "config";
import { TokenErrorCodes, TokenType } from "types";

const CreatePinView = () => {
  const history = useHistory();

  const { createPinToken } = useParams<{
    createPinToken: string;
  }>();

  const { isLoading } = useQuery<string, AxiosError<PasswordApiError>>(
    ["createPinValidation", createPinToken, TokenType.CreatePin],
    api.getTokenInformation,
    {
      onSuccess: () => {
        window.location.replace(config.MOBILE_APP_DOWNLOAD_URL);
      },
      onError: (error) => {
        const { tokenErrorCode } = getTokenErrorInfo(error);
        const isTokenUsed = tokenErrorCode === TokenErrorCodes.TokenReusage;

        if (isTokenUsed) {
          window.location.replace(config.MOBILE_APP_DOWNLOAD_URL);
        } else {
          history.replace(
            getNewUserRedirectRoute(error, createPinToken, false)
          );
        }
      },
    }
  );

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  return null;
};

export default CreatePinView;
