import { FileError } from "react-dropzone";

export enum FileStatus {
  Added = "added",
  Loading = "loading",
  Loaded = "loaded",
}

interface FileData {
  id: string;
  name: string;
  description: string;
  path?: string;
}

export interface FileItem extends FileData {
  size: number;
}

export interface FileInterface extends FileItem {
  status: FileStatus;
  isError?: boolean;
  errorText?: string;
  isRemoveForbidden?: boolean;
}

export interface Progress {
  [key: string]: number;
}

export interface FileRejection {
  file: File;
  errors: FileError[];
}
