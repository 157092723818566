import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { AssetManifest, WindowWithExternalAppComponents } from "types/common";
import Loader from "../Loader";

interface Props {
  name?: string;
  host?: string;
  [key: string]: any;
}

const getExternalAppComponent = (name: string = "") => {
  const {
    externalAppComponents = {},
  } = window as WindowWithExternalAppComponents;

  return externalAppComponents[name];
};

const ExternalApp = (props: Props) => {
  const { name, host, ...otherProps } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const ExternalAppComponent = getExternalAppComponent(name);
  const [isReady, setIsReady] = useState(!!ExternalAppComponent);
  const [isError, setIsError] = useState(false);
  const assetId = `external-app-asset-${name}-0`;

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout;
    let isActive = true;
    const isComponentReady = !!getExternalAppComponent(name);

    if (!document.getElementById(assetId)) {
      fetch(`${host}asset-manifest.json`)
        .then((res) => res.json())
        .then((manifest: AssetManifest) => {
          const { entrypoints } = manifest;
          let assetsToLoad = entrypoints.length;

          const onLoad = () => {
            if (isActive) {
              assetsToLoad--;

              if (assetsToLoad === 0) {
                setIsReady(true);
              }
            }
          };

          entrypoints.forEach((entry, index) => {
            const isEntryCss =
              (entry.split(".").pop() || "").toLowerCase() === "css";

            if (isEntryCss) {
              const link = document.createElement("link");

              link.id = `external-app-asset-${name}-${index}`;
              link.rel = "stylesheet";
              link.type = "text/css";
              link.href = `${host}${entrypoints[index]}`;
              link.onload = onLoad;
              document.head.appendChild(link);
            } else {
              const script = document.createElement("script");

              script.id = `external-app-asset-${name}-${index}`;
              script.crossOrigin = "";
              script.src = `${host}${entrypoints[index]}`;
              script.onload = onLoad;
              script.onerror = () => {
                setIsError(true);
              };
              document.head.appendChild(script);
            }
          });
        })
        .catch(() => {
          setIsError(true);
        });
    } else if (!isComponentReady) {
      pollingInterval = setInterval(() => {
        if (getExternalAppComponent(name)) {
          clearInterval(pollingInterval);
          setIsReady(true);
        }
      }, 100);
    } else {
      setIsReady(true);
    }

    return () => {
      isActive = false;
      clearInterval(pollingInterval);
      setIsReady(false);
      setIsError(false);
    };
  }, [assetId, host, name]);

  if (isError) {
    return <div>Unexpected error occured.</div>;
  }

  return (
    <>
      {isReady && !!ExternalAppComponent ? (
        <ExternalAppComponent match={match} history={history} {...otherProps} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ExternalApp;
