import axios, { AxiosError } from "axios";
import { useCallback, useEffect } from "react";

import { UserInfo } from "types/domain/User";

export interface TokenResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  tokenType: string;
  success: boolean;
  error: any;
}

interface Tokens {
  token?: string;
  refreshToken?: string;
}

declare global {
  interface Window {
    currentRefreshRequest: Promise<Tokens | void>;
    isTokenRefreshing: boolean;
    lastUsedRefreshToken: string;
  }
}

export const refreshTokenRequest = async (
  refreshTokenUrl: string,
  token: string,
  withCredentials: boolean
): Promise<Tokens> => {
  const {
    data: { accessToken, refreshToken },
  } = await axios.post<TokenResponse>(
    refreshTokenUrl,
    {
      refreshToken: token,
    },
    { withCredentials }
  );

  return {
    token: accessToken,
    refreshToken,
  };
};

const refreshTokenWithResend = async (
  error: AxiosError,
  refreshTokenUrl: string,
  helpers: {
    updateTokens: (tokens: Tokens) => void;
    refreshToken?: string;
    logout: () => void;
    isWithCredentials: boolean;
  }
) => {
  const {
    refreshToken = "",
    updateTokens,
    logout,
    isWithCredentials,
  } = helpers;

  if (
    !window.isTokenRefreshing &&
    refreshToken !== window.lastUsedRefreshToken
  ) {
    delete axios.defaults.headers.Authorization;
    window.isTokenRefreshing = true;
    window.lastUsedRefreshToken = refreshToken;
    window.currentRefreshRequest = refreshTokenRequest(
      refreshTokenUrl,
      refreshToken,
      isWithCredentials
    )
      .then((res: Tokens) => {
        if (res.token) {
          updateTokens(res);
          return res;
        }
        throw new Error("Refresh token failed");
      })
      .catch(logout)
      .finally(() => {
        window.isTokenRefreshing = false;
      });
  }

  const tokens = await window.currentRefreshRequest;

  if (tokens && tokens.token) {
    error.config.headers.Authorization = `Bearer ${tokens.token}`;
    return axios(error.config);
  }

  return Promise.reject(error);
};

const useAccessRejectedInterceptor = (
  user: UserInfo | null,
  setData: (data: UserInfo | null) => void,
  refreshTokenUrl?: string,
  isWithCredentials = false
) => {
  const updateTokens = useCallback(
    (tokens: Tokens) => {
      setData({
        ...user,
        ...tokens,
      });
    },
    [user, setData]
  );

  const logout = useCallback(() => setData(null), [setData]);

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isAccessRejected = error?.response?.status === 401;

        if (!user) {
          return Promise.reject(error);
        }

        if (isAccessRejected && refreshTokenUrl) {
          return refreshTokenWithResend(error, refreshTokenUrl, {
            updateTokens,
            refreshToken: user?.refreshToken,
            logout,
            isWithCredentials,
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [logout, updateTokens, user, refreshTokenUrl, isWithCredentials]);
};

export default useAccessRejectedInterceptor;
