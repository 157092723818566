import { useEffect, useRef } from "react";

export function useResizeObserver(
  ref: React.RefObject<HTMLElement>,
  handler: (entry: DOMRectReadOnly) => void
): void {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    let resizeObserver: ResizeObserver;

    if (ref.current) {
      resizeObserver = new ResizeObserver((entries) => {
        handlerRef.current?.(entries[0].contentRect);
      });

      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);
}

export default useResizeObserver;
