import React from "react";

import { IconProps } from "../../types/common";

const TransfersIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M10.87 17.93v-1.79H4.32a1 1 0 01-1-1 1 1 0 011-1h6.55v-1.79a.5.5 0 01.85-.35l2.78 2.79c.19.2.19.51 0 .71l-2.78 2.79a.5.5 0 01-.85-.36z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M5.5 17v2.33a1 1 0 01-1 1h-.2a1 1 0 01-1-1V17h2.2zm0-4v-2.67h13v9a1 1 0 001 1h.2a1 1 0 001-1v-9c.46 0 .68-.57.33-.87l-8.36-5.53a1 1 0 00-1.34 0L2.97 9.46a.5.5 0 00.33.87V13h2.2z"
    />
  </svg>
);

export default TransfersIcon;
