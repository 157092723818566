import React from "react";

import { IconProps } from "../../types/common";

const WarningIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#EEC200",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M19.53 20.5a2 2 0 001.73-3l-7.53-13a2 2 0 00-3.46 0l-7.53 13a2 2 0 001.73 3h15.06zm-7.53-7a1 1 0 01-1-1v-2a1 1 0 011-1 1 1 0 011 1v2a1 1 0 01-1 1zm-1 2v2h2v-2h-2z"
    />
  </svg>
);

export default WarningIcon;

