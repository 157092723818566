import { QueryKey } from "react-query";

export const USER_DATA_STORAGE_KEY = "userData";
export const SESSION_ID_STORAGE_KEY = "sessionId";
export const PREV_LOCATION_KEY = "prevLocation";
export const CANCELED_REQUEST = "isCanceled";
export const REFRESH_TOKEN_URL = "account/refreshToken";
export const ROUTES = {
  root: "/",
  login: "/login",
  createPin: "/create-pin",
  createPassword: "/create-password",
  forgotPassword: "/forgot-password",
  acceptWebInvite: "/web-invite",
  acceptMobileInvite: "/mobile-invite",
  inactiveInvite: "/inactive-invite",
  accountCreated: "/account-created",
  enroll: "/enroll",
  brokenLink: "/broken-link",
  profile: "/profile",
  employees: "/employees",
  transactions: "/transactions",
  transfers: "/transfers",
  requests: "/requests",
  remittances: "/remittances",
  inventory: "/inventory",
  company: "/company/:id/*",
  endMarket: "/end-market",
  invoices: "/invoices",
  paymentBalance: "/payment-balance",
};

export const PERMISSIONS = {
  employees: "WebApp.Employees",
  profile: "WebApp.Participants",
  transfers: "WebApp.StorageTransfersMenu",
  transactions: "WebApp.Transactions",
  requests: "WebApp.LotrAndGotrTirePickupRequest",
  remittances: "WebApp.RemittancesForProducer",
  inventory: "WebApp.Inventory",
  endMarket: "WebApp.EndMarket",
  invoices: "WebApp.Invoices",
  paymentBalance: "WebApp.PaymentBalanceAdjustmentTab",
};

export const companiesQueryKey: QueryKey = "companies";
