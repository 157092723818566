import { useState, useEffect, SyntheticEvent, RefObject } from "react";

import useScrollToIndex from "./useScrollToIndex";
import useOnClickOutside from "./useOnClickOutside";

interface Option {
  label: string;
  value: string | number;
}

const defaultOptions: Option[] = [];
const defaultOnChange = () => {};

const useMultiDropdown = (
  ref: RefObject<HTMLElement>,
  optionsWrapperRef: RefObject<HTMLElement>,
  options: Option[] = defaultOptions,
  onChange: (
    event: SyntheticEvent,
    values: (number | string)[],
    value: number | string
  ) => void = defaultOnChange,
  value: (number | string)[] = []
) => {
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const hasOptions = !!options.length;

  useOnClickOutside([ref, optionsWrapperRef], () => setIsDropdownOpened(false));

  const changeDropdownState = (isOpened: boolean = !isDropdownOpened) => {
    setIsDropdownOpened(isOpened);
    setSelectedIdx(-1);
  };

  const getUpdatedValue = (
    initialValue: (number | string)[],
    newValue: number | string
  ) => {
    return initialValue.includes(newValue)
      ? initialValue.filter((v) => v !== newValue)
      : [...initialValue, newValue];
  };

  const onValueChange = (
    e: React.SyntheticEvent,
    ...newValues: (number | string)[]
  ) => {
    const updatedValues = newValues.reduce(
      (acc, newValue) => getUpdatedValue(acc, newValue),
      value
    );

    onChange(e, updatedValues, newValues[0]);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const getMinIdx = (idx: number) => Math.max(idx - 1, 0);
    const getMaxIdx = (idx: number) => Math.min(idx + 1, options.length - 1);
    let otherKeyPressed = false;
    let newIdx = selectedIdx;

    switch (e.key) {
      case "Tab":
        changeDropdownState(false);
        break;
      case "ArrowUp":
        if (hasOptions && isDropdownOpened) {
          newIdx = getMinIdx(selectedIdx);
        }
        break;
      case "ArrowDown":
        if (hasOptions && isDropdownOpened) {
          newIdx = getMaxIdx(selectedIdx);
        }
        break;
      case "Enter":
        if (!isDropdownOpened) {
          changeDropdownState(true);
          break;
        }
        if (selectedIdx !== -1) {
          onValueChange(e, options[selectedIdx].value);
        }
        break;
      case " ":
        if (!isDropdownOpened) {
          changeDropdownState(true);
        }
        break;
      case "Escape":
        if (isDropdownOpened) {
          changeDropdownState(false);
        }
        break;
      default:
        otherKeyPressed = true;
        break;
    }
    if (newIdx !== selectedIdx) {
      setSelectedIdx(newIdx);
    }
    if (!otherKeyPressed && e.key !== "Tab") {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  useScrollToIndex(isDropdownOpened, selectedIdx, optionsWrapperRef, options);

  useEffect(() => {
    setSelectedIdx(options.length ? 0 : -1);
  }, [options]);

  return {
    selectedIdx,
    isDropdownOpened,
    onKeyDown,
    onValueChange,
    changeDropdownState,
  };
};

export default useMultiDropdown;
