import React from "react";

import { IconProps } from "../../types/common";

const EndMarketIcon = ({
  width = "24",
  height = "24",
  style,
  className,
  fillClassName,
  fillColor = "#fff",
}: IconProps) => (
  <svg
    style={style}
    width={width}
    height={height}
    aria-hidden="true"
    viewBox="0 0 24 24"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={fillColor!}
      className={fillClassName}
      d="M9.3 5.7a1 1 0 0 0 1 1h5.59L4.99 17.6A1 1 0 1 0 6.42 19L17.3 8.11v5.6a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1Z"
    />
  </svg>
);

export default EndMarketIcon;
