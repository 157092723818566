import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import LOGO_URL from "../../assets/images/logo.svg";

import styles from "./LogoLayout.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const LogoLayout = (props: Props) => {
  const { t } = useTranslation();
  const { children, className } = props;

  return (
    <div className={styles.etLogoWrapper}>
      <main className={classnames(styles.etLogo, className)}>
        <img
          src={LOGO_URL}
          alt={t("logoAltText")}
          className={styles.etLogoImage}
        />
        {children}
      </main>
    </div>
  );
};

export default LogoLayout;
