import React, { useState, useRef, useEffect, useCallback } from "react";
import classnames from "classnames";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import {
  ModuleRegistry,
  GridReadyEvent,
  GridApi,
  ColumnApi,
  BodyScrollEvent,
  RowNode,
  IRowModel,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import ClearFilters from "./Filters/ClearFilters";
import EmptyGridView from "./Views/EmptyGridView";
import LoadingGridView from "./Views/LoadingGridView";

import TextFilter from "./Filters/TextFilter";
import FloatingTextFilter from "./Filters/FloatingTextFilter";
import SelectFilter from "./Filters/SelectFilter";
import MultiSelectFilter from "./Filters/MultiSelectFilter";
import SingleSelectFilter from "./Filters/SingleSelectFilter";
import SingleSelectComponent from "./Filters/SingleSelectFilterComponent";
import DateRangeFilter from "./Filters/DateRangeFilter";
import DateFilter from "./Filters/DateFilter";
import { DataGridFilters, DataGridOptions } from "./modules/types";

import "./DataGrid.scss";

interface IRowFullModel extends IRowModel {
  rowsToDisplay: RowNode[];
}

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface Props extends AgGridReactProps {
  idField?: string;
  options?: DataGridOptions;
  filters?: DataGridFilters;
  setOptions?: (options: DataGridOptions) => void;
  setFilters?: (filters: DataGridFilters) => void;
  fetchMore?: () => void;
  canFetchMore?: boolean;
  isLoading?: boolean;
  isFetchingMore?: false | "previous" | "next";
  setVisibleRows?: (rows: any[]) => void;
  className?: string;
  assignGridApi?: (api: GridApi) => void;
}

const defaultProps = {
  defaultColDef: {},
  frameworkComponents: {},
  fetchMore: () => {},
  setVisibleRows: () => {},
  assignGridApi: () => {},
  defaultRowHeight: 52,
  setOptions: () => {},
  setFilters: () => {},
  headerHeight: 32,
  floatingFiltersHeight: 40,
};

const DataGrid = (props: Props) => {
  const {
    idField = "id",
    options,
    filters,
    setOptions = defaultProps.setOptions,
    setFilters = defaultProps.setFilters,
    rowData,
    defaultColDef = defaultProps.defaultColDef,
    frameworkComponents = defaultProps.frameworkComponents,
    fetchMore = defaultProps.fetchMore,
    canFetchMore,
    isFetchingMore,
    isLoading = false,
    setVisibleRows = defaultProps.setVisibleRows,
    className,
    assignGridApi = defaultProps.assignGridApi,
    rowHeight = defaultProps.defaultRowHeight,
    headerHeight = defaultProps.headerHeight,
    floatingFiltersHeight = defaultProps.floatingFiltersHeight,
    ...gridProps
  } = props;
  const gridRef = useRef<HTMLDivElement>(null);
  const gridViewportRef = useRef<HTMLDivElement | null>(null);
  const readyTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const onBodyScroll = useCallback(
    (e?: BodyScrollEvent) => {
      if (
        e?.direction !== "horizontal" &&
        gridViewportRef.current &&
        canFetchMore &&
        !isFetchingMore
      ) {
        const {
          scrollTop,
          clientHeight,
          scrollHeight,
        } = gridViewportRef.current;
        const isScrolledToBottom =
          Math.abs(scrollHeight - scrollTop - clientHeight) <= 3;

        if (isScrolledToBottom) {
          fetchMore();
        }
      }
    },
    [canFetchMore, isFetchingMore, fetchMore]
  );

  useEffect(() => {
    onBodyScroll();
  }, [rowData, onBodyScroll]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.api.setFilterModel(filters);
    if (options) {
      params.columnApi.applyColumnState({
        state: [
          {
            colId: options.sortField,
            sort: options.sortDirection,
          },
        ],
      });
    }

    assignGridApi(params.api);

    gridViewportRef.current =
      gridRef.current?.querySelector('[ref="eBodyViewport"]') || null;

    readyTimeoutRef.current = setTimeout(() => setIsReady(true), 0);
  };

  const updateRowsCount = useCallback(
    (updateLayout = false) => {
      const displayedRows = (
        (gridApi?.getModel() as IRowFullModel)?.rowsToDisplay || []
      ).map((r) => r.data);

      setVisibleRows(displayedRows);

      if (!updateLayout || isLoading) {
        return;
      }

      if (displayedRows.length) {
        gridApi?.hideOverlay();
      } else {
        gridApi?.showNoRowsOverlay();
      }
    },
    [gridApi, setVisibleRows, isLoading]
  );

  const onFilterChanged = () => {
    setFilters(gridApi?.getFilterModel() as DataGridFilters);
    updateRowsCount(true);
  };

  const onSortChanged = () => {
    const columnState = columnApi?.getColumnState() || [];
    const sortedColumn = columnState.find((c) => c.sort);

    setOptions({
      ...options,
      sortField: sortedColumn?.colId as string,
      sortDirection: sortedColumn?.sort as "asc" | "desc",
    });
    updateRowsCount(false);
  };

  useEffect(() => {
    if (isReady) {
      updateRowsCount(true);
    }
  }, [isReady, rowData, updateRowsCount]);

  useEffect(() => {
    return () => {
      clearTimeout(readyTimeoutRef.current!);
    };
  }, []);

  return (
    <div
      ref={gridRef}
      className={classnames("ag-theme-alpine", "dataGrid", className)}
    >
      <AgGridReact
        frameworkComponents={{
          textFilter: TextFilter,
          floatingTextFilter: FloatingTextFilter,
          selectFilter: SelectFilter,
          singleSelectFilter: SingleSelectFilter,
          singleSelectComponent: SingleSelectComponent,
          multiSelectFilter: MultiSelectFilter,
          dateRangeFilter: DateRangeFilter,
          dateFilter: DateFilter,
          emptyGridView: EmptyGridView,
          loadingGridView: LoadingGridView,
          clearFilters: ClearFilters,
          ...frameworkComponents,
        }}
        defaultColDef={{
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          floatingFilter: true,
          unSortIcon: true,
          comparator: (v1, v2, n1, n2, isInverted) => (isInverted ? -1 : 1),
          ...defaultColDef,
        }}
        loadingOverlayComponent="loadingGridView"
        noRowsOverlayComponent="emptyGridView"
        suppressLoadingOverlay={!isLoading}
        immutableData
        suppressMultiSort
        disableStaticMarkup
        suppressRowTransform
        alwaysShowVerticalScroll
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        floatingFiltersHeight={floatingFiltersHeight}
        sortingOrder={["asc", "desc"]}
        onGridReady={onGridReady}
        onBodyScroll={onBodyScroll}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        getRowNodeId={(row) => row[idField]}
        rowData={isReady ? rowData : undefined}
        gridOptions={{
          suppressBrowserResizeObserver: true,
        }}
        {...gridProps}
      />
    </div>
  );
};

export default DataGrid;
